import React from 'react'
import Draggable from 'react-draggable';
import Modal from 'react-modal'
import { MdClose } from 'react-icons/md';
import { UserInfo } from 'actions/administration-tools';
import Button from 'components/Button';

type LeaveCommentProps = {
  isOpen: boolean,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  currentUser: UserInfo | null;
  declineUser: (tenant: string, id: number, comment: string) => Promise<void>;
};

const LeaveComment: React.FC<LeaveCommentProps> = ({ isOpen, setIsOpen, currentUser, declineUser}) => {
  const [inputContent, setInputContent] = React.useState<string>('');

  const onChangeSearchContent = (event: any) => {
    setInputContent(event.target.value);
  }

  const closeModal = () => {
    setInputContent('')
    setIsOpen(false);
  }

  const onDecline = () => {
    if(!currentUser?.tenant || !currentUser?.id) return;
    declineUser(currentUser?.tenant, currentUser?.id, inputContent);
    setInputContent('')
    setIsOpen(false)
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      bodyOpenClassName={null}
      portalClassName="react-modal-container"
      overlayClassName="react-modal-overlay"
      className="react-modal-wrapper"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <Draggable
        enableUserSelectHack={false}
        handle=".modal-title"
        bounds="body"
      >
        <div className={`react-modal top-left`}>
          <div className="modal-header">
            <div className="modal-title">Decline reason</div>
            <div className="modal-header-buttons">
              <div onClick={() => closeModal()} className="modal-close" >
                <MdClose />
              </div>
            </div>
          </div>
          <div className="modal-content">
            <div>
              <textarea
                placeholder={`Leave a reason why are you declining ${currentUser?.firstname} :`} value={inputContent}
                onChange={onChangeSearchContent} className='edit-mode' rows={10} />
            </div>
            <div className="modal-footer">
              <div style={{ display: 'flex' }}>
                <Button disabled={!inputContent.length} onClick={() => onDecline()} color="action" full isButton>
                  Submit
                </Button>
                <Button onClick={() => closeModal()} color="primary" isButton>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    </Modal>
  )
}
export default LeaveComment;