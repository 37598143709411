import React, { useState, useRef, useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeState } from 'state/themeReducer';
import { UserInfo, administrationToolsActions } from 'actions/administration-tools';
import { clearUsers, moveNewUserToRegistered } from 'state/administrationToolsReducer';
import LeaveComment from './LeaveComment';
import dayjs from 'dayjs';

type NewUsersProps = {};

const NewUsers: React.FC<NewUsersProps> = () => {
  const theme = useSelector((state: { theme: ThemeState }) => state.theme.theme)
  const updatedUserFromRegisteredToNew = useSelector((state: any) => state.administrationTools.user)
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<UserInfo[]>([]);
  const [showCommentBox, setShowCommentBox] = React.useState<boolean>(false);
  const [currentUser, setCurrentUser] = React.useState<UserInfo | null>(null)
  const dispatch = useDispatch()

  const approveUser = async (tenant: string, id: number) => {
    try {
      const newUserData = await administrationToolsActions().approveUser({ tenant, userId: id });
      dispatch(moveNewUserToRegistered(newUserData))
      setRowData(prevRowData => {
        return prevRowData.filter(user => user.id !== id);
      });
    } catch (error) {
      console.error("Error approving user:", error);
    }
  }

  const [columnDefs, _] = useState([
    { field: 'tenant' },
    { field: 'username' },
    { field: 'email' },
    {
      field: 'registerDate',
      valueGetter: (params: any) => {
        return params.data.registerDate ? new Date(params.data.registerDate) : null;
      },
      valueFormatter: (params: any) => {
        return params.value ? dayjs(params.value).format("DD.MM.YYYY HH:mm") : '';
      },
    },
    { field: 'comments', tooltipField: 'comments' },
    {
      field: 'approved',
      filter: 'agTextColumnFilter',
      hide: true,
      filterValueGetter: (params: any) => params.data.approved.toString()
    },
    {
      field: 'deactivated',
      filter: 'agTextColumnFilter',
      hide: true,
      filterValueGetter: (params: any) => params.data.deactivated.toString()
    },
    {
      headerName: 'ACTIONS',
      field: 'actions',
      filter: false,
      sortable: false,
      cellRenderer: (data: any) => {
        return <div className='xa-grid-btns-cell'>
          <Button onClick={() => approveUser(data.data.tenant, data.data.id)} color="primary" isButton>Approve</Button>
          <Button onClick={() => { setCurrentUser(data.data); setShowCommentBox(true) }} color="danger" isButton>Decline</Button>
        </div>;
      },
    }
  ]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    flex: 1,
    resizable: true
  }), []);

  const onGridReady = useCallback((params: any) => {
    params.api.showLoadingOverlay();
    administrationToolsActions().getUsersInformation().then((response: UserInfo[]) => {
      const filteredData = response.filter(user => !user.approvedDate);
      setRowData(filteredData);
      params.api.hideOverlay();
    })
  }, []);

  const emptyGrid = '<div class="xa-grid-empty"><div class="image"></div><div class="text">No rows found!</div></div>';

  React.useEffect(() => {
    if (updatedUserFromRegisteredToNew) {
      setRowData(prevRowData => {
        const userIndex = prevRowData.findIndex(user => user.id === updatedUserFromRegisteredToNew.id);

        if (userIndex !== -1) {
          const updatedRowData = [...prevRowData];
          updatedRowData[userIndex] = updatedUserFromRegisteredToNew;

          return updatedRowData;
        }

        return prevRowData;
      });
      dispatch(clearUsers())
    }
  }, [updatedUserFromRegisteredToNew])

  const declineUser = async (tenant: string, id: number, comment: string) => {
    try {
      const newUserData = await administrationToolsActions().declineUser({ tenant, userId: id, comment });
      dispatch(moveNewUserToRegistered(newUserData))
      setRowData(prevRowData => {
        return prevRowData.filter(user => user.id !== id);
      });
    } catch (error) {
      console.error("Error while declining user:", error);
    }
  }

  return (
    <div className='xa-admin-column new-users'>
      <div className='xa-section-title'>New Users</div>
      <div className={`xa-grid${theme === 'dark' ? ' ag-theme-alpine-dark' : ' ag-theme-alpine'}`}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={false}
          suppressRowClickSelection={true}
          onGridReady={onGridReady}
          overlayNoRowsTemplate={emptyGrid}
          pagination={true}
          paginationPageSize={10}
        />
        <LeaveComment isOpen={showCommentBox} setIsOpen={setShowCommentBox} currentUser={currentUser} declineUser={declineUser} />
      </div>
    </div>
  );
}

export default NewUsers;
