import humanizeKey from 'humanize-key';

interface Props {
  name: string,
}

const ElementRow: React.FC<Props> = ({ name }) => {
  return (
    <div className="xa-attributes-list-row">
      <div className="xa-attributes-list-value">{humanizeKey(name)}</div>
    </div>
  )
}

export default ElementRow;
