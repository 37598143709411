import React, { useState, useRef, useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { TenantInfo, administrationToolsActions } from 'actions/administration-tools';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { ThemeState } from 'state/themeReducer';

type TenantsProps = {};

const Tenants: React.FC<TenantsProps> = () => {
  const theme = useSelector((state: {theme: ThemeState}) => state.theme.theme)
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<TenantInfo[]>([]);
  const newUserToRegistered = useSelector((state: any) => state.administrationTools.user)
  const updatedUserFromRegisteredToNew = useSelector((state: any) => state.administrationTools.user)

  const [columnDefs, _] = useState([
    { field: 'name' },
    { field: 'users' },
    { field: 'newUsers' },
    {field: 'admins'},
    {field: 'deactiveUsers'},
    { field: 'lastActive',
    valueGetter: (params: any) => {
      return params.data.lastActive ? dayjs(params.data.lastActive).format("DD.MM.YYYY HH:mm") : null;
    }
  },
    { field: "status" }
  ]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    flex: 1,
    resizable: true
  }), []);

  const getTenantInfo = async (id: string) => {
    try {
      const tenantData = await administrationToolsActions().getSingleTenantInformation(id);
      setRowData(prevRowData => {
        const tenantIndex = prevRowData.findIndex(tenant => tenant.name === tenantData.name);
        if (tenantIndex !== -1) {
          const updatedRowData = [...prevRowData];
          updatedRowData[tenantIndex] = tenantData;
          return updatedRowData;
        }
  
        return prevRowData;
      });
    } catch (error) {
      console.error("Error updating tenant:", error);
    }
  }

  React.useEffect(() => {
    if(newUserToRegistered){
      getTenantInfo(newUserToRegistered.tenant)
    }
    if(updatedUserFromRegisteredToNew){
      getTenantInfo(updatedUserFromRegisteredToNew.tenant)
    }
  },[newUserToRegistered, updatedUserFromRegisteredToNew])

  const onGridReady = useCallback((params: any) => {
    params.api.showLoadingOverlay();
    administrationToolsActions().getTenantsInformation().then((response: TenantInfo[]) => {
      setRowData(response);
      params.api.hideOverlay();
    })
  }, []);

  const emptyGrid = '<div class="xa-grid-empty"><div class="image"></div><div class="text">No rows found!</div></div>';

  return (
    <div className='xa-admin-column tenants'>
      <div className='xa-section-title'>Tenants</div>
      <div className={`xa-grid${theme === 'dark' ? ' ag-theme-alpine-dark' : ' ag-theme-alpine'}`}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={false}
          suppressRowClickSelection={true}
          onGridReady={onGridReady}
          overlayNoRowsTemplate={emptyGrid}
          pagination={true}
          paginationPageSize={10}
        />
      </div>
    </div>
  );
}

export default Tenants;
