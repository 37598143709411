const BASE_API_URL = `${process.env.REACT_APP_API_URL}/api`;

export const getUrls = () => [`${BASE_API_URL}/network/sections/geo`,
  new RegExp(`${BASE_API_URL}/accidents/years/[0-9]*/geo`),
  new RegExp(`${BASE_API_URL}/assets/types/[a-zA-Z]*/assets/geo`),
  new RegExp(`${BASE_API_URL}/schemes/years/[0-9]*/geo`),
  new RegExp(`${BASE_API_URL}/schemes/fwps/years/[0-9]*/geo`),
  new RegExp(`${BASE_API_URL}/schemes/programmed/years/[0-9]*/geo`),
  new RegExp(`${BASE_API_URL}/schemes/programmed/[0-9]*/years/[0-9]*/geo`),
  new RegExp(`${BASE_API_URL}/schemes/parameters/[0-9]*/years/[0-9]*/geo`),
  new RegExp(`${BASE_API_URL}/schemes/parameters/[0-9]*/treatments/years/[0-9]*/geo`),
  new RegExp(`${BASE_API_URL}/schemes/treatments/years/[0-9]*/geo`),
  new RegExp(`${BASE_API_URL}/surveys/types/[a-zA-Z0-9]*/years/[0-9]*/geo`),
  new RegExp(`${BASE_API_URL}/surveys/inspection-types/[a-zA-Z0-9]*/years/[0-9]*/geo`),
  new RegExp(`${BASE_API_URL}/performance/[a-zA-Z0-9]*/years/[0-9]*/geo`),
]
