import React, { useState, useEffect } from 'react';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai'
import { Categories, useLegendActions } from 'actions/legend';
import Tree from "rc-tree";
import "rc-tree/assets/index.css";
import Symbology from './symbology';
import VisualisationRule from './visualisationRules';
import { Menu, Item, useContextMenu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';
import {
  MdColorLens, MdFullscreenExit, MdOutlineRule
} from 'react-icons/md';
import { RgbaColor } from "react-colorful";
import { SymbologyModelRecord } from 'models/SymbologyModel';
import { SymbologyModel } from 'models/SymbologyModel';
import { LayerAttributeStyle } from 'models/LayerAttributeStyle';
import { LayerRuleStyleRecord } from 'models/LayerRuleStyle';
import './style.scss';
import { getLegendStructure } from "./getLegendStructure";
import { FiRefreshCw } from 'react-icons/fi';

interface Props {
  onNodeChecked: (checkedNodes: Array<any>, currentSelectedNode?: any, refresh?: boolean) => void,
  setLayerStyle: (key: string, color: RgbaColor, width: number, size: number, lineStyle: string, markerStyle: string, angle: number) => void,
  setLayerVisualisationRules: (key: string, type: string, style: SymbologyModel, layerAttributeStyles: LayerAttributeStyle[]) => void,
  zoomToSection: (key: string) => void,
  bringLayerToTop: (layerKey: string) => void,
  styles: SymbologyModelRecord,
  layerRulesStyles: LayerRuleStyleRecord,
  setCheckedNodesState: (p: any[]) => void,
  handleContextMenuRefreshClicked: (selectedKeys: Key[]) => void,
  setToolbarRefreshLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setTreeData: React.Dispatch<React.SetStateAction<any[]>>,
  treeData: any[],
  checkedNodesState: any[],
  toolbarRefreshLoading: boolean,
  layerRefreshLoading: never[],
  setLayerRefreshLoading: React.Dispatch<React.SetStateAction<never[]>>,
}

declare type Key = string | number;
type FavouriteEntry = [string, any];
const MENU_ID = 'legend-menu';

const Legend: React.FC<Props> = ({
   onNodeChecked,
   setLayerStyle,
   setLayerVisualisationRules,
   bringLayerToTop,
   zoomToSection,
   styles = {},
   layerRulesStyles = {},
   setCheckedNodesState,
   handleContextMenuRefreshClicked,
   setTreeData,
   treeData,
   checkedNodesState,
   layerRefreshLoading,
   setLayerRefreshLoading
                                 }) => {
  const legendActions = useLegendActions();
  const [loading, setLoading] = useState(true);
  const [symbologyOpened, setSymbologyOpened] = useState(false);
  const [visualisationRulesOpened, setVisualisationRulesOpened] = useState(false);
  const [inDragMode, setInDragMode] = useState(false);
  const [symbologyData, setSymbologyData] = useState({
    key: '',
    category: '',
    title: '',
  });
  const [visualisationRulesData, setVisualisationRulesData] = useState({
    key: '',
    category: '',
    title: '',
    parameterId: -1
  });
  const [checkedKeys, setCheckedKeys] = useState<{
    checked: Key[];
    halfChecked: Key[];
  } | Key[]>(["network-layer"]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const toggleSymbologyOpened = () => setSymbologyOpened(prevState => !prevState);
  const toggleVisualisationRulesOpened = () => setVisualisationRulesOpened(prevState => !prevState);
  const [favourites, setFavourites] = useState<Map<string, any>>(new Map());
  const [favouritesIsAdding, setFavouritesIsAdding] = useState<boolean | null>(null);
  const [hideVisRules,setHideVisRules] = useState<boolean>(false);

  const {show} = useContextMenu({
    id: MENU_ID,
  });

  useEffect(() => {
    // Load favourites from localStorage when component mounts
    const currentTenant = localStorage.getItem('tenantId');
    const currentUser = JSON.parse(localStorage.getItem('userData') as any) as any
    if (!currentTenant || !currentUser) return;

    const savedFavourites = localStorage.getItem(`favourites_${currentTenant}_${currentUser?.username}`);

    if (savedFavourites) {
      const parsedData = JSON.parse(savedFavourites)
      setFavourites(new Map(parsedData));
    }
  }, []);


  useEffect(() => {
    if (treeData.length > 0) {
      // @ts-ignore
      const updatedTreeData = [...treeData];
      const currentTenant = localStorage.getItem('tenantId');
      const currentUser = JSON.parse(localStorage.getItem('userData') as any) as any

      const favoritesNodeIndex = updatedTreeData.findIndex(node => node.title === "Favourites");

      if (favouritesIsAdding && favoritesNodeIndex >= 0) {
        const lastEntry = [...favourites.entries()].pop();
        const [lastKey, lastValue] = lastEntry || [undefined, undefined];
        updatedTreeData[1].children = [...updatedTreeData[1].children, {
          ...lastValue,
          key: `${'favourites_' + lastValue?.key}`,
          selectable: true,
          category: "Favourites"
        }]
      } else if (favourites.size > 0 && favoritesNodeIndex === -1) {
        const lastEntry = [...favourites.entries()].pop();
        const [lastKey, lastValue] = lastEntry || [undefined, undefined];

        updatedTreeData.splice(1, 0, {
          key: 'favourites-node',
          title: 'Favourites',
          selectable: false,
          isLeaf: false,
          checkable: false,
          children: [{...lastValue, key: `${'favourites_' + lastValue?.key}`, selectable: true, category: "Favourites"}]
        });
      } else if (favourites.size === 0 && favoritesNodeIndex !== -1 && favouritesIsAdding !== null) {
        updatedTreeData.splice(1, 1);
        localStorage.removeItem(`favourites_${currentTenant}_${currentUser?.username}`);
        localStorage.removeItem(`treeData_${currentTenant}_${currentUser?.username}`)
      }
      setTreeData(updatedTreeData);
      if (currentTenant && currentUser) {
        localStorage.setItem(`favourites_${currentTenant}_${currentUser?.username}`, JSON.stringify([...favourites.entries()]));
        localStorage.setItem(`treeData_${currentTenant}_${currentUser?.username}`, JSON.stringify(treeData[1]));
      }
    }
  }, [favourites]);

  useEffect(() => {
    const currentTenant = localStorage.getItem('tenantId');
    const currentUser = JSON.parse(localStorage.getItem('userData') as any) as any
    if (currentTenant && currentUser && favourites.size > 0) {
      localStorage.setItem(`treeData_${currentTenant}_${currentUser?.username}`, JSON.stringify(treeData[1]));
    }
  }, [treeData])

  useEffect(() => {
    const onGetLegend = async () => {
      try {
        const legendResponse = await legendActions.getLegend();
        const trData = getLegendStructure(legendResponse);
        const currentTenant = localStorage.getItem('tenantId');
        const currentUser = JSON.parse(localStorage.getItem('userData') as any) as any
        const savedTreeData = localStorage.getItem(`treeData_${currentTenant}_${currentUser?.username}`) as any;

        if(savedTreeData){
          trData[1] = JSON.parse(savedTreeData)
        }
        setTreeData(trData);
        setLoading(false);
      } catch (err: any) {

      }
    }
    onGetLegend();
  }, []);

  useEffect(() => {
    let networkKey = treeData.find(data => data.category === "Network" && data.isChecked === true);
    if (networkKey) {
      setCheckedNodesState([networkKey]);
    }
  }, [treeData])

  const getNodes = (node: any) => {
    if (node?.children?.length > 0 && node.children[0].isLeaf) {
      return node.children;
    }

    if (node?.children?.length > 0) {
      return node.children.map((x: any) => getNodes(x)).flat();
    }

    return [];
  }

  const nodeSelected = (selectedKeys: Key[], info: any) => {
    if (info.selected) {
      bringLayerToTop(info.node.key.replace('favourites_', ''));

      setSelectedKeys([info.node.key]);

      if (!info.node.checked) {

        onNodeChecked([{
          checked: true,
          title: info.node.title,
          key: info.node.key.replace('favourites_', ''),
          endPoint: info.node.endPoint,
          category: info.category,
        }], {key: info.node.key.replace('favourites_', ''), title: info.node.title, category: info.node.category});
        setCheckedKeys((x) => {
          if (Array.isArray(x)) {
            if (info.node.key.startsWith("favourites_")) {
              return [...x, info.node.key, info.node.key.replace('favourites_', '')]
            } else {
              return [...x, info.node.key, `${'favourites_' + info.node.key}`]
            }
          } else {
            return {
              checked: [...x.checked, info.node.key],
              halfChecked: x.halfChecked,
            }
          }
        })
      } else {
        onNodeChecked([], {key: info.node.key, title: info.node.title, category: info.node.category});
      }
    } else {
      setSelectedKeys([]);
    }
  };

  const nodeChecked = (checked: {
    checked: Key[];
    halfChecked: Key[];
  } | Key[], info: any) => {
    // @ts-ignore
    const favourites_checkedKeys = checkedKeys.filter((item) => item.startsWith('favourites_'))

    // @ts-ignore
    const uniqueCombined = [...new Set([...checked, ...favourites_checkedKeys])];
    // @ts-ignore
    let localChecked = [...uniqueCombined]
    if (info.node.key.startsWith("favourites_")) {
      if (info.checked) {
        // @ts-ignore
        localChecked.push(info.node.key.replace('favourites_', ''))
      } else {
        localChecked = localChecked.filter((item) => item !== info.node.key.replace('favourites_', ''))
        localChecked = localChecked.filter((item) => item !== info.node.key)
      }
    } else {
      if (info.checked) {
        // @ts-ignore
        localChecked.push(`${'favourites_' + info.node.key}`)
      } else {
        localChecked = localChecked.filter((item) => item !== `${'favourites_' + info.node.key}`)
      }
    }
    setCheckedKeys(localChecked);
    if (info.node.isLeaf) {

      if (info.checked === false && info.node.key === (selectedKeys.length > 0 ? selectedKeys[0] : null)) {
        setSelectedKeys([]);
      }

      onNodeChecked([{
        checked: info.checked,
        title: info.node.title,
        key: info.node.key.replace('favourites_', ''),
        endPoint: info.node.endPoint,
        category: info.halfCheckedKeys[0],
      }]);
    } else {
      const nodes = getNodes(info.node).map((item: any) => (
        {
          checked: info.checked,
          title: item.title,
          key: item.key.replace('favourites_', ''),
          endPoint: item.endPoint,
          category: item.category,
        }
      ));
      onNodeChecked(nodes);
    }
  }
  const onNodeDoubleClick = (e: any, node: any) => {
    if (node.isLeaf && node.isChecked) {
      setSymbologyData({
        key: node.key,
        category: node.category,
        title: node.title,
      });
      setSymbologyOpened(true);
    }
  }

  const onRightClick = (info: {
    event: React.MouseEvent;
    node: any;
  }) => {
    if (info.node.isLeaf && !info.node.checked) {
      setCheckedKeys(ch => {
        if (Array.isArray(ch)) {
          if (info.node.key.startsWith("favourites_")) {
            return Array.from(new Set([...ch, info.node.key, info.node.key.replace('favourites_', '')]));
          } else {
            return Array.from(new Set([...ch, info.node.key, `${'favourites_' + info.node.key}`]));
          }
        } else {
          return {
            checked: info.node.key.startsWith("favourites_") ? Array.from(new Set([...ch.checked, info.node.key, info.node.key.replace('favourites_', '')])) : Array.from(new Set([...ch.checked, info.node.key, `${'favourites_' + info.node.key}`])),
            halfChecked: ch.halfChecked,
          }
        }
      });
      onNodeChecked([{
        checked: true,
        title: info.node.title,
        key: info.node.key.replace('favourites_', ''),
        endPoint: info.node.endPoint,
        category: info.node.category,
        // category: info.halfCheckedKeys[0],
      }]);
      bringLayerToTop(info.node.key.replace('favourites_', ''));
      setSelectedKeys([info.node.key.replace('favourites_', '')]);
      setSelectedCategory(info.node.category);
    }
    // Visualisation Rules need to be hidden for certain layers
    setHideVisRules(false);
    if(info.node.key.split('+')[0] === 'Surveys'){
        setHideVisRules(true)
    }
    //
    if (info.node.isLeaf && info.node.checked) {
      show({
        event: info.event,
        props: {
          key: info.node.key.replace('favourites_', ''),
          category: info.node.category,
          title: info.node.title,
          parameterId: info.node.parameterId
        }
      });
      bringLayerToTop(info.node.key.replace('favourites_', ''));
      setSelectedKeys([info.node.key.replace('favourites_', '')]);
      setSelectedCategory(info.node.category);
    }
  };

  const onDragEnd = () => setInDragMode(false);

  const onDragStart = () => setInDragMode(true);

  const handleOpenSymbology = (ev: any) => {
    setSymbologyData({
      key: ev.props.key,
      category: ev.props.category,
      title: ev.props.title,
    });
    setSymbologyOpened(true);
  };

  const handleOpenVisualisationRules = (ev: any) => {

    setVisualisationRulesData({
      key: ev.props.key,
      category: ev.props.category,
      title: ev.props.title,
      parameterId: ev.props.parameterId
    });
    setVisualisationRulesOpened(true);
  };

  const handleZoomToSection = (ev: any) => {
    zoomToSection(ev.props.key);
  };

  const findParentNode = (key: string, nodes: any, category: any = null) => {
    for (const node of nodes) {
      if (node.children) {
        const currentCategory = category || node;
        for (const child of node.children) {
          if (child.key === key) {
            return {
              ...node,
              categoryName: currentCategory?.title
            };
          }
          const found: any = findParentNode(key, node.children, currentCategory);
          if (found) return found;
        }
      }
    }
    return null;
  }


  const handleImageClick = (nodeData: any, event: any) => {
    event.stopPropagation();

    const newFavorites = new Map(favourites);

    const parentNode = findParentNode(nodeData.key, treeData);
    const parentName = parentNode ? parentNode.title : 'Root';


    if (newFavorites.has(nodeData.key) || newFavorites.has(`${"favourites_" + nodeData.key}`)) {
      // @ts-ignore
      const updatedTreeData = [...treeData];
      const filteredTreeItems = updatedTreeData[1].children.filter((item: any) => {
        return item.key !== nodeData.key && item.key !== `favourites_${nodeData.key}`;
      });
      updatedTreeData[1].children = filteredTreeItems
      newFavorites.delete(nodeData.key);
      newFavorites.delete(`${"favourites_" + nodeData.key}`)
      newFavorites.delete(nodeData.key.replace('favourites_', ''));
      setFavouritesIsAdding(false);
      setTreeData(updatedTreeData)
    } else {

      newFavorites.set(nodeData.key, nodeData);
      const localNode = {...nodeData, title: `${nodeData.title} (${parentName})`, categoryName: parentNode.categoryName}
      newFavorites.set(`${"favourites_" + nodeData.key}`, localNode)
      setFavouritesIsAdding(true)
    }
    setFavourites(newFavorites);
  };

  const titleRender = (nodeData: any) => {
    let isSelectedKeysRefreshing = false;

    if (layerRefreshLoading.hasOwnProperty(nodeData.key)) {
      isSelectedKeysRefreshing = layerRefreshLoading[nodeData.key];
    }

    return (
      <div id="favourite_tooltip" data-tip="favourite_tooltip" data-for="favourite_tooltip" className='title_render--container'>
        <div className='title_render--container_title' title={`${nodeData.title} ${nodeData.category === 'Favourites' ? `\n~${nodeData.categoryName}~` : ''}`}>{nodeData.title}</div>
        {nodeData.isLeaf &&
          <div style={{display: "flex", alignItems: 'center', gap: '5px'}}>
            {isSelectedKeysRefreshing && <FiRefreshCw className='rotating'/>}
            {favourites.has(nodeData.key) ?
              <AiFillStar className={`favourites_star_img`} color="#FFC845" size={19}
                          onClick={(event: any) => handleImageClick(nodeData, event)}/>
              :
              <AiOutlineStar className={`favourites_star_img`} size={19}
                             onClick={(event: any) => handleImageClick(nodeData, event)}/>
            }
          </div>
        }
      </div>
    );
  };

  return (
    <>
      {symbologyOpened && (<Symbology
        isOpen={symbologyOpened}
        closeModal={toggleSymbologyOpened}
        itemKey={symbologyData.key}
        category={symbologyData.category}
        title={symbologyData.title}
        setLayerStyle={setLayerStyle}
        currentWidth={styles[symbologyData.key]?.currentWidth}
        currentSize={styles[symbologyData.key]?.currentSize}
        currentLineStyle={styles[symbologyData.key]?.currentLineStyle}
        currentMarkerStyle={styles[symbologyData.key]?.currentMarkerStyle}
        currentColor={styles[symbologyData.key]?.currentColor}
        currentAngle={styles[symbologyData.key]?.currentAngle}
        layerType={styles[symbologyData.key]?.type}
      />)}
      {visualisationRulesOpened && (<VisualisationRule
        isOpen={visualisationRulesOpened}
        closeModal={toggleVisualisationRulesOpened}
        itemKey={visualisationRulesData.key}
        category={visualisationRulesData.category}
        title={visualisationRulesData.title}
        parameterId={visualisationRulesData.parameterId}
        layerType={styles[visualisationRulesData.key]?.type}
        defaultStyle={styles[visualisationRulesData.key]}
        layerRulesStyles={layerRulesStyles[visualisationRulesData.key]}
        setLayerVisualisationRules={setLayerVisualisationRules}
      />)}
      {!inDragMode && (<Menu id={MENU_ID}>
        <Item onClick={handleOpenSymbology}><MdColorLens/>Symbology</Item>
        {!hideVisRules && <Item onClick={handleOpenVisualisationRules}><MdOutlineRule/>Visualisation Rules</Item>}
        <Item onClick={handleZoomToSection}><MdFullscreenExit/>Zoom to extent</Item>
        <Item onClick={() => {
          handleContextMenuRefreshClicked(selectedKeys);
          setLayerRefreshLoading((prevState) => ({
            ...prevState,
            [selectedKeys.toString()]: true,
          }));
        }}
        >
          <FiRefreshCw size={10} style={{marginRight: '5px'}}/>Refresh</Item>
      </Menu>)}
      <div className="xa-legend-container">
        <Tree
          showLine
          selectable
          checkable
          defaultExpandParent={false}
          autoExpandParent={false}
          defaultExpandAll={false}
          showIcon={false}
          treeData={treeData}
          onCheck={nodeChecked}
          onSelect={nodeSelected}
          checkedKeys={checkedKeys}
          selectedKeys={selectedKeys}
          onRightClick={onRightClick}
          onDoubleClick={onNodeDoubleClick}
          titleRender={titleRender}
          defaultExpandedKeys={["favourites-node"]}
        />
      </div>
    </>
  );
}
export default Legend;
