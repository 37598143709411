import { get } from 'utils/request';
import { getRefreshDataHeader } from "../utils/getRefreshDataHeader";

export interface PerformanceInfo {
  id: number,
  sectionLabel: string,
  startChainage: number,
  endChainage: number,
  xsp: string,
  value: number,
}

const performanceActions = () => {

  const getPerformanceDetails = async (id: string, refreshData: boolean = false): Promise<PerformanceInfo> => {
    const response = await get<PerformanceInfo>(`performance/values/${id}`, getRefreshDataHeader(refreshData));
    const responseData = response.data;
    return responseData;
  }

  return {
    getPerformanceDetails,
  };
};

export { performanceActions };
