import { MdOutlineClose, MdMoreHoriz } from "react-icons/md";
import { IoCheckmarkSharp } from "react-icons/io5";
import dayjs from 'dayjs';


export const filterParams = {
    filterOptions: [
      'empty',
      {
        displayKey: 'active',
        displayName: 'Active',
        predicate: (_: any, cellValue: any) => cellValue != null && cellValue === false,
        numberOfInputs: 0,
      },
      {
        displayKey: 'inactive',
        displayName: 'Inactive',
        predicate: (_: any, cellValue: any) => cellValue != null && cellValue === true,
        numberOfInputs: 0,
      },
    ],
    maxNumConditions: 1,
  };
  

export const reactColumnDefs = [
    { field: 'username', filter: true, },
    { field: 'firstname', filter: true, },
    { field: 'surname', filter: true, },
    { field: 'email', tooltipField:'approvedDate', },
    { field: 'tenant', filter: true },
    {
      field: 'approved',
      cellRenderer: (data: any) => {
        return <div className='xa-grid-admin-cell'>
          {data.data.approved ? <IoCheckmarkSharp size={24} color="var(--xa-success)" /> : <MdOutlineClose size={24} color="var(--xa-danger)" />}
        </div>;
      },
    },
    {
      field: 'approvedBy',
      filter: true
    },
    {
      field: 'approvedDate',
      headerTooltip: 'Approved Date',
      valueGetter: (params: any) => {
        return params.data.approvedDate ? new Date(params.data.approvedDate) : null;
      },
      valueFormatter: (params: any) => {
        return params.value ? dayjs(params.value).format("DD.MM.YYYY HH:mm") : '';
      },
      filter: 'agDateColumnFilter',
    },
    {
      field: 'registerDate',
      valueGetter: (params: any) => {
        return params.data.registerDate ? new Date(params.data.registerDate) : null;
      },
      valueFormatter: (params: any) => {
        return params.value ? dayjs(params.value).format("DD.MM.YYYY HH:mm") : '';
      },
      filter: 'agDateColumnFilter',
    },
    {
      field: 'lastActive',
      valueGetter: (params: any) => {
        return params.data.lastActive ? new Date(params.data.lastActive) : null;
      },
      valueFormatter: (params: any) => {
        return params.value ? dayjs(params.value).format("DD.MM.YYYY HH:mm") : '';
      },
      filter: 'agDateColumnFilter',
    },
    {
      field: 'admin',
      cellRenderer: (data: any) => {
        return <div className='xa-grid-admin-cell'>
          {data.data.admin ? <IoCheckmarkSharp size={24} color="var(--xa-success)" /> : <MdOutlineClose size={24} color="var(--xa-danger)" />}
        </div>;
      },
    },
    {
      headerName: 'Status',
      field: 'deactivated',
      filterParams: filterParams,
      cellRenderer: (data: any) => {
        return <div className='xa-grid-status-cell'>
          <div className={`xa-grid-status ${data.data.deactivated ? 'inactive' : 'active'}`}>{data.data.deactivated ? 'Deactivated' : 'Activated'}</div>
        </div>;
      },
    },
    {
      field: 'deactivatedDate',
      headerTooltip: 'Deactivated Date',
      valueGetter: (params: any) => {
        return params.data.deactivatedDate ? new Date(params.data.deactivatedDate) : null;
      },
      valueFormatter: (params: any) => {
        return params.value ? dayjs(params.value).format("DD.MM.YYYY HH:mm") : '';
      },
      filter: 'agDateColumnFilter',
    },
    {
      field: 'comments'
    }
  ]