import React from 'react'
import Modal from 'react-modal';
import Draggable from 'react-draggable';
import '../style.scss'
import { TbArrowsMinimize, TbArrowsMaximize } from 'react-icons/tb';
import { MdClose } from 'react-icons/md';
import AssetHubOptions from './AssetHubOptions';
import AssetHubLegend from './AssetHubLegends';
import AssetHubInfo from './AssetHubInfo';
import { assetActions } from 'actions/asset';
import { FiRefreshCw } from 'react-icons/fi';
import '../style.scss';


interface AssetHubProps {
  closeModal: (title: string) => void,
  data: any,
  setWasAssetHubRefreshedClicked: React.Dispatch<React.SetStateAction<boolean>>,
  setWasAssetHubRefreshLoading: React.Dispatch<React.SetStateAction<boolean>>,
  wasAssetHubRefreshedClicked: boolean,
  wasAssetHubRefreshLoading: boolean,
}
const FAKE_BRIDGES = [
  {
    name: 'Trent Bridge',
    src: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Gaoliang_Bridge.JPG/1200px-Gaoliang_Bridge.JPG"
  },
  {
    name: "Second Bridge",
    src: "https://live.staticflickr.com/65535/36767106826_8f98d07e23_b.jpg"
  },
  {
    name: "Third Bridge",
    src: "https://i.pinimg.com/originals/a8/b0/b4/a8b0b4f3713a761c729540cb96c356cf.jpg"
  },
  {
    name: 'Trent Bridge',
    src: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Gaoliang_Bridge.JPG/1200px-Gaoliang_Bridge.JPG"
  },
  {
    name: "Second Bridge",
    src: "https://live.staticflickr.com/65535/36767106826_8f98d07e23_b.jpg"
  },
  {
    name: "Third Bridge",
    src: "https://i.pinimg.com/originals/a8/b0/b4/a8b0b4f3713a761c729540cb96c356cf.jpg"
  },
]

const AssetHub: React.FC<AssetHubProps> = ({
   closeModal,
   data,
   wasAssetHubRefreshedClicked,
   wasAssetHubRefreshLoading,
   setWasAssetHubRefreshLoading,
   setWasAssetHubRefreshedClicked
 }) => {
  const [isModalMinimized, setModalMinimized] = React.useState<boolean>(false);
  const toggleModalState = () => setModalMinimized(prevState => !prevState);
  const [modalDetails, setModalDetails] = React.useState<any>();
  const [infoTrigger,setInfoTrigger] = React.useState<boolean>(false);
  const [spanInfoError,setSpanInfoError] = React.useState<boolean>(false);

  React.useEffect(() => {
    assetActions().getAssetDetails(data.id, wasAssetHubRefreshedClicked).then((result: any) => {
      setModalDetails(result);
      setWasAssetHubRefreshLoading(false);
    });
  }, [infoTrigger, wasAssetHubRefreshLoading])

  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={true}
        bodyOpenClassName={null}
        portalClassName="react-modal-container"
        overlayClassName="react-modal-overlay"
        className="react-modal-wrapper"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
      >
        <Draggable
          enableUserSelectHack={false}
          handle=".modal-header"
          bounds="body"
        >
          <div className={`react-modal top-left${isModalMinimized ? ' minimized' : ''}`}>
            <div className="modal-header">
              <div className="modal-title-container-asset-hub">
                <div className="modal-title">Asset Hub</div>
                <FiRefreshCw className={wasAssetHubRefreshedClicked && wasAssetHubRefreshLoading ? 'modal-refresh-button  rotating' : 'modal-refresh-button '}
                             onClick={() => {
                               setWasAssetHubRefreshedClicked(true);
                               setWasAssetHubRefreshLoading(true);
                             }
                             }/>
              </div>
              <div className="modal-header-buttons">
                <div className="modal-control" onClick={toggleModalState}>
                  {isModalMinimized ? <TbArrowsMaximize /> : <TbArrowsMinimize />}
                </div>
                <div className="modal-close" onClick={() => closeModal('Attributes')}>
                  <MdClose />
                </div>
              </div>
            </div>
            <div className="modal-content">
              <div className='asset_hub--container'>
                <AssetHubLegend
                  data={data}
                  modalDetails={modalDetails}
                  setModalDetails={setModalDetails}
                  infoTrigger={infoTrigger}
                  setInfoTrigger={setInfoTrigger}
                  setSpanInfoError={setSpanInfoError}
                  wasAssetHubRefreshedClicked={wasAssetHubRefreshedClicked}
                  setWasAssetHubRefreshLoading={setWasAssetHubRefreshLoading}
                  wasAssetHubRefreshLoading={wasAssetHubRefreshLoading}
                />
                <AssetHubInfo modalDetails={modalDetails} spanInfoError={spanInfoError} />
                <AssetHubOptions
                  id={data.id}
                  FAKE_BRIDGES={FAKE_BRIDGES}
                  wasAssetHubRefreshedClicked={wasAssetHubRefreshedClicked}
                  wasAssetHubRefreshLoading={wasAssetHubRefreshLoading}
                />
              </div>
            </div>
          </div>
        </Draggable>
      </Modal>
    </>
  )
}

export default AssetHub
