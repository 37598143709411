import dayjs from 'dayjs';
import { get } from 'utils/request';
import { getRefreshDataHeader } from "../utils/getRefreshDataHeader";

export interface AccidentInfo {
  index: string,
  policeForce: number,
  severity: number,
  numberOfVehicles: number,
  numberOfCasualties: number,
  accidentDate: string,
  dayOfWeek: number,
  district: number,
  highway: string,
  firstRoadClass: number,
  firstRoadNumber: number,
  secondRoadClass: number,
  secondRoadNumber: number,
  roadType: number,
  speedLimit: number,
  junctionDetail: number,
  junctionControl: number,
  pedestrianCrossingHumanControl: number,
  pedestrianCrossingPhysicalFacilities: number,
  lightConditions: number,
  weatherConditions: number,
  surfaceConditions: number,
  specialConditions: number,
  carriagewayHazards: number,
  urbanOrRuralArea: number,
  officerAtScene: number,
  location: string,
  text: string,
}

const accidentActions = () => {

  const getAccidentDetails = async (index: string, refreshData: boolean): Promise<AccidentInfo> => {
    const response = await get<AccidentInfo>(`accidents/${index}`, getRefreshDataHeader(refreshData));
    const responseData = response.data;
    if (responseData.accidentDate) responseData.accidentDate = dayjs(responseData?.accidentDate).format('YYYY-MM-DD');
    return responseData;
  }

  return {
    getAccidentDetails,
  };
};

export { accidentActions };
