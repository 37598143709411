import React from 'react'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs';
// @ts-ignore
import { Swiper, SwiperSlide, SwiperCore } from 'swiper/react';
// @ts-ignore
import { Navigation } from 'swiper/modules';
// @ts-ignore
import { FreeMode, Thumbs } from 'swiper/modules';
import '../../style.scss';

interface ImageHubInterface {
    images: Array<{ name: string, src: string }>
}

const ImageHub: React.FC<ImageHubInterface> = ({ images }) => {
    const [thumbsSwiper, setThumbsSwiper] = React.useState<SwiperCore | null>(null);
    const [mySwiper, setMySwiper] = React.useState<SwiperCore | null>(null);
    const [assetActiveIndex,setAssetActiveIndex] = React.useState<number>(0)

    return (
        <div>
            <div className="xa-imageHub">
               <span className='xa-imageHub__asset-name'>{images[assetActiveIndex].name}</span>
                <div className='xa-imageHub__main-swiper'>
                <BsArrowLeftCircle onClick={() => mySwiper.slidePrev()} size={30} className="xa-imageHub__arrow left" />
                <Swiper
                    spaceBetween={10}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    onSwiper={(arg: SwiperCore) => setMySwiper(arg)}
                    className='xa-imageHub__swiper'
                    onSlideChange={(img: SwiperCore) => setAssetActiveIndex(img.realIndex)}
                >
                    {images.map((img, index) => {
                        return (
                            <SwiperSlide key={index} >
                                <img src={img.src} alt="asset_hub_img" className='xa-imageHub__swiper-img' />
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
                <BsArrowRightCircle className='xa-imageHub__arrow right' onClick={() => mySwiper.slideNext()} size={30} />
                </div>
                
                <Swiper
                    onSwiper={(swiper: SwiperCore) => setThumbsSwiper(swiper)}
                    spaceBetween={5}
                    slidesPerView={4}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="xa-imageHub-thumbs"
                >
                {images.map((img, index) => {
                        return (
                            <SwiperSlide key={index} >
                                <img src={img.src} alt="asset_hub_img" className='xa-imageHub__swiper-img thumb'  />
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        </div>
    )
}

export default ImageHub;