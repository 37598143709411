import React from 'react';

type ChartOption = {
  value: string;
  label: string;
};

type ToggleSwitchWithTextProps = {
  options: ChartOption[];
  selectedValue: string;
  onChange: (value: string) => void;
  name: string;
};

const ToggleSwitchWithText: React.FC<ToggleSwitchWithTextProps> = ({ options, selectedValue, onChange , name}) => {

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <>
      <form className="custom-switch">
        {options.map((option, index) => (
          <React.Fragment key={option.value}>
            <input
              type="radio"
              id={`switch_${name}_${index}`}
              name={name}
              value={option.value}
              onChange={handleOptionChange}
              checked={selectedValue === option.value}
            />
            <label htmlFor={`switch_${name}_${index}`}>{option.label}</label>
          </React.Fragment>
        ))}
      </form>
    </>
  );
};

export default ToggleSwitchWithText;