import React, { useState, useRef, useEffect, Key } from 'react';
// @ts-expect-error
import View from "@arcgis/core/views/MapView";
// @ts-expect-error
import Measurement from "@arcgis/core/widgets/Measurement";
// @ts-expect-error
import WebTileLayer from "@arcgis/core/layers/WebTileLayer";
// @ts-expect-error
import OpenStreetMapLayer from "@arcgis/core/layers/OpenStreetMapLayer";
// @ts-expect-error
import ArcGISMap from "@arcgis/core/Map";
// @ts-expect-error
import GeoJSONLayer from '@arcgis/core/layers/GeoJSONLayer';
// @ts-expect-error
import * as projection from "@arcgis/core/geometry/projection";
import { useAppSelector } from 'hooks/appSelector';
import { MapView } from "components/Map";
import NavBar from 'components/NavBar';
import ToolBar from 'components/Toolbar';
import SearchContainer from 'components/SearchContainer';
import UserContainer from 'components/UserContainer';
import Charting from 'containers/Charting';
import Attributes from 'components/Attributes';
import { SearchDetails } from 'models/SearchDetails';
import { RgbaColor } from "react-colorful";
import {
  blue,
  orange,
  purple,
  yellow,
  red,
  black,
} from 'utils/colors';
import { SymbologyModelRecord } from 'models/SymbologyModel';
import { SymbologyModel } from 'models/SymbologyModel';
import { LayerAttributeStyle } from 'models/LayerAttributeStyle';
import { LayerRuleStyleRecord } from 'models/LayerRuleStyle';
import './style.scss';
// @ts-expect-error
import esriConfig from "@arcgis/core/config";
// @ts-expect-error
import debounce from 'lodash/debounce';
import { appInsights } from "../../index";
import { MAX_ZOOM } from 'esri/map';
import { getUrls } from "../../esri/getUrls";

const BASE_API_URL = `${process.env.REACT_APP_API_URL}/api`;

let clickEvent: any;
let highlight: any;

function Dashboard() {
  const user = useAppSelector((state) => state.user);
  const [view, setView] = useState<View | null>(null);
  const measurementWidget = useRef<Measurement | null>(null);
  const [currentLayer, setCurrentLayer] = useState("StadiaMaps");
  const [baseLayer, setBaseLayer] = useState<any>(null);
  const [requestMade, setRequestMade] = useState(0);
  const [layerData, setLayerData] = useState<any>({});
  const [attributesModalData, setAttributesModalData] = useState(null);
  const [modalState, setModalState] = useState<Record<string, boolean>>({
    'Major asset group overview': false,
    'Basemaps': true,
    'Attributes': false,
    'Legend': true,
  });
  const [actionsState, setActionsState] = useState<Record<string, boolean>>({
    'Measure': false,
    'Legend': true,
    'Basemaps': true,
  });
  const [searchContent, setSearchContent] = useState<SearchDetails>({
    selectedItem: undefined,
    type: 'network',
    selectedKey: 'network-layer',
    selectedLabel: 'Network',
    selectedCategory: 'Network',
  });
  const [selectedNode, setSelectedNode] = useState<any>(null);
  const [layersStyle, setLayersStyle] = useState<SymbologyModelRecord>({});
  const [layerRulesStyles, setLayerRulesStyles] = useState<LayerRuleStyleRecord>({});
  const showModal = (title: string) => setModalState((prevState) => ({ ...prevState, [title]: true }));
  const onCloseModal = (title: string) => setModalState((prevState) => ({ ...prevState, [title]: false }));
  const [primaryIsExpanded, setPrimaryIsExpanded] = useState<boolean | null>(null);
  const [secondaryIsExpanded, setSecondaryIsExpanded] = useState<boolean | null>(null);
  const [zoomStackIsAllowed, setZoomStackIsAllowed] = useState<boolean>(false);
  const [isClusteringEnabled, setIsClusteringEnabled] = useState<boolean>(user?.clustering || true);
  const [checkedNodesState, setCheckedNodesState] = useState<any>([]);
  const [layerRefreshLoading, setLayerRefreshLoading] = useState([]);
  const [savedTimestampWhenRefreshingLayer, setSavedTimestampWhenRefreshingLayer] = useState<number>(0);
  const [isFirstRefresh, setIsFirstRefresh] = useState<boolean>(true);

  const clusterConfig = {
    type: "cluster",
    clusterRadius: "150px",
    clusterMaxSize: "84px",
    clusterMinSize: '44px',
    labelingInfo: [
      {
        symbol: {
          type: "text",
          color: "white",
          haloColor: "black",
          haloSize: "1px",
          font: {
            size: 12,
            weight: "bold",
          }
        },
        labelPlacement: "center-center",
        labelExpressionInfo: {
          expression: "Text($feature.cluster_count, '#,###')"
        }
      }
    ]
  };


  const onSelectNode = (currentSearchContent: SearchDetails) => {
    var lyr = view.map.layers;
    lyr.forEach((layer: any) => {
      
      if (layer.id === currentSearchContent.selectedKey) {
        const parcelQuery = {
          spatialRelationship: "intersects", // Relationship operation to apply
          //geometry: geometry,  // The sketch feature geometry
          where: `name = '${currentSearchContent.selectedItem}'`,
          outFields: ["*"], // Attributes to return
          returnGeometry: true
        };


        view.whenLayerView(layer)
          .then((layerView: any) => {
            layer.queryFeatures(parcelQuery)
              .then((results: any) => {
                const feature = results.features[0];
                if (feature?.attributes) {
                  setAttributesModalData({ ...feature.attributes });
                  showModal('Attributes');

                  if (highlight) {
                    highlight.remove();
                  }
                  highlight = layerView.highlight(feature.attributes["__OBJECTID"]);
                  view.goTo(
                    {
                      target: feature.geometry,
                      zoom: 16
                    },
                    {
                      duration: 2000,
                      easing: "in-out-expo"
                    });
                } else {
                  const newAttributesModalData: any = {
                    name: currentSearchContent.selectedItem,
                    category: "network"
                  };
                  setAttributesModalData(newAttributesModalData);
                  showModal('Attributes');
                  if (highlight) {
                    highlight.remove();
                  }
                  view.goTo(
                    {
                      target: layer.fullExtent,
                    },
                    {
                      duration: 2000,
                      easing: "in-out-expo"
                    });
                }
              });
          })
      }
    });
  }

  const onCloseAttributesModal = (title: string) => {
    if (highlight) {
      highlight.remove();
    }
    setAttributesModalData(null);
    onCloseModal(title);
  }

  const onSelectSearchDetailsType = (type: string) => {

    if (type === 'network') {
      setSearchContent({
        ...searchContent,
        type: 'network',
        selectedKey: 'network-layer',
        selectedLabel: 'Network',
        selectedCategory: 'Network',
      });
    } else if (type === 'current-layer') {
      if (selectedNode != null) {
        setSearchContent({
          ...searchContent,
          type: 'current-layer',
          selectedKey: selectedNode.key,
          selectedLabel: selectedNode.title,
          selectedCategory: selectedNode.category,
        });
      }
    }
  };

  const isNodeSelected = () => {
    return selectedNode !== null && selectedNode !== undefined;
  }

  const onSelectSearchDetailsItem = (item: string) => {
    const newSearchContent = { ...searchContent, selectedItem: item };
    setSearchContent(newSearchContent);
    onSelectNode(newSearchContent);
  }

  const onAction = async (title: string, refreshLegendToolbar: boolean = false) => {
    if (title === 'Measure') {
      setActionsState((prevState) => ({ ...prevState, [title]: !prevState[title] }));
      if (measurementWidget.current !== null && measurementWidget.current.isResolved()) {
        measurementWidget.current.destroy();
        measurementWidget.current = null;
      } else {
        measurementWidget.current = new Measurement({
          view: view,
          activeTool: "distance",
        });
        view.ui.add(measurementWidget.current, "bottom-right");
        measurementWidget.current.startMeasurement();
      }
    } else if (title === 'Legend' && !refreshLegendToolbar) {
      setModalState((prevState) => ({ ...prevState, 'Legend': !prevState['Legend'] }));
      setActionsState((prevState) => ({ ...prevState, 'Legend': !prevState['Legend'] }));
    } else if (title === 'Basemaps') {
      setModalState((prevState) => ({ ...prevState, 'Basemaps': !prevState['Basemaps'] }));
      setActionsState((prevState) => ({ ...prevState, 'Basemaps': !prevState['Basemaps'] }));
    }
  }

  const zoomToSection = (key: string) => {
    if (layerData[key]) {
      view.goTo(layerData[key].fullExtent)
    }
  }

  useEffect(() => {
    const findInterceptor = esriConfig.request.interceptors?.find((interceptor: {
      urls: string | string[];
    }) => interceptor.urls.includes('https://api.emapsite.com/'));
    if (!findInterceptor) {
      esriConfig.request.interceptors.push({
        urls: ["https://api.emapsite.com/"],
        before: () => {
          setRequestMade(prev => prev + 1);
        }
      });
    }
  }, []);

  useEffect(() => {
    let tileRequests = 0;
    tileRequests += requestMade;
    const sendDataToAzureApplicationInsight = debounce(() => {
      if (tileRequests > 0) {
        const tenant = localStorage.getItem('tenantId')?.replace(/['"]+/g, '');
        appInsights.trackEvent({ name: 'CustomEvent', properties: { tileRequests, service: 'emapsite', tenant } });
        setRequestMade(0);
      }
    }, 5000);
    sendDataToAzureApplicationInsight();

    return () => {
      tileRequests = 0;
    };
  }, [requestMade]);

  const computeStyle = (style: SymbologyModel, type: string) => {
    let st = {
      color: style.currentColor,
      size: `${style.currentSize}px`,
      width: `${style.currentWidth}px`,
      style: style.style,
      outline: {},
      currentStyle: `symbols-sls-${style.style}`,
      type: type,
      angle: style.currentAngle,
      currentWidth: style.currentWidth,
      currentSize: style.currentSize,
      // currentLineStyle: 'solid',
      // currentMarkerStyle: 'circle',
      currentColor: style.currentColor,
      currentAngle: style.currentAngle,
    };

    if (style.currentMarkerStyle === 'cross' || style.currentMarkerStyle === 'x') {
      st.outline = {
        width: 6,  // points,
        color: style.currentColor,
      };
    }

    if (style.currentLineStyle && type === 'simple-line') {
      st.style = style.currentLineStyle;
      st.currentStyle = `symbols-sls-${style.currentLineStyle}`;
    }
    if (style.currentMarkerStyle && type === 'simple-marker') {
      st.style = style.currentMarkerStyle;
      st.currentStyle = `symbols-sls-${style.currentMarkerStyle}`;
    }
    return st;
  }

  const composeValueExpression = (layerAttributeStyles: LayerAttributeStyle[]) => {
    if (layerAttributeStyles.length === 0) {
      return '';
    }
    let composedResult = 'When(';

    layerAttributeStyles.forEach(element => {
      if (element.dataType === "Integer") {
        let ruleName = `$feature.${element.name.replaceAll(' ', '_')} ${element.firstComparison?.value} ${element.firstValue}`;
        if (element?.ruleChaining?.label && element?.secondComparison?.label && element?.secondValue) {
          ruleName += `${element.ruleChaining.value === "Or" ? " ||" : " &&"} $feature.${element.name.replaceAll(' ', '_')} ${element.secondComparison?.value} ${element.secondValue}`;
        }
        composedResult += `${ruleName}, '${getRuleName(element)}', `;
      } else {
        composedResult += `$feature.${element.name.replaceAll(' ', '_')} == '${element.value}', '${element.name}-${element.value}', `;
      }
    });

    composedResult += `'other')`;
    return composedResult;
  }

  const getRuleName = (la: LayerAttributeStyle): string => {
    if (la?.dataType === "Integer") {
      let ruleName = `${la.name} ${la.firstComparison?.value} ${la.firstValue}`;
      if (la?.ruleChaining?.label && la?.secondComparison?.label && la?.secondValue) {
        ruleName += `${la.ruleChaining.value === "Or" ? " Or" : " And"} ${la.secondComparison?.value} ${la.secondValue}`;
      }
      return ruleName
    }
    return la.name;
  }

  const  setLayerVisualisationRules  = (key: string, type: string, style: SymbologyModel, layerAttributeStyles: LayerAttributeStyle[]) => {
    let url = layerData[key]?.url;
    url = url.split('?attributes=')[0];

    // layerData[key].url = `${url}?attributes=${attributeName}`;
    let renderer: any = {
      defaultSymbol: {},
    };

    renderer.type = "unique-value";
    const st = computeStyle(style, type);
    renderer.defaultSymbol = st;

    updateStyle(key, {
      ...st,
      type: type,
    });

    renderer.valueExpression = composeValueExpression(layerAttributeStyles);


    // renderer.valueExpression = `When($feature.attributes.id == 3836, '3836', 'other')`;
    //   renderer.valueExpression =   `
    //   var id = $feature.id;
    //   Console($feature.id);
    //   When(
    //     id == 3836, "3836",
    //     "other"
    //   );
    // `;

    // const ust = computeStyle(uniqueStyle, type);
    const uniqueStyles = layerAttributeStyles.map((layerAttrybuteStyle) => ({
      name: layerAttrybuteStyle.name,
      label: getRuleName(layerAttrybuteStyle),
      dataType: layerAttrybuteStyle.dataType,
      value: layerAttrybuteStyle.value,
      style: computeStyle(layerAttrybuteStyle.uniqueStyle, type),
      firstComparison: layerAttrybuteStyle.firstComparison,
      secondComparison: layerAttrybuteStyle.secondComparison,
      ruleChaining: layerAttrybuteStyle.ruleChaining,
      firstValue: layerAttrybuteStyle.firstValue,
      secondValue: layerAttrybuteStyle.secondValue,
    }));

    const newUrl = `${url}?${[...new Set(layerAttributeStyles.map((m) => `attributes=${m.name}`))].join('&')}`;

    updateLayerRulesStyle(key, newUrl, renderer.valueExpression, uniqueStyles);

    // updateVisRuleStyle(key, {
    //   color: ust.color,
    //   currentColor: ust.color,
    //   width: uniqueStyle.width,
    //   currentWidth: uniqueStyle.currentWidth,
    //   size: ust.size,
    //   currentSize: uniqueStyle.currentSize,
    //   style: ust.style,
    //   currentLineStyle: ust.style,
    //   currentMarkerStyle: ust.style,
    //   currentStyle: `symbols-sls-${ust.style}`,
    //   angle: ust.angle,
    //   currentAngle: ust.angle,
    //   type: type,
    // }, renderer.valueExpression, /* 'id', '3836', */ attributeName, attributeValue, `${url}?attributes=${attributeName}`);

    renderer.uniqueValueInfos = uniqueStyles.map((us) => ({ value: us.dataType === "Integer" ? us.label : `${us.name}-${us.value}`, symbol: { ...us.style } }));



    const geoj = new GeoJSONLayer({
      id: key,
      url: newUrl,
      popupEnabled: false,
      renderer: renderer,
      spatialReference: { wkid: 3857 },
      outFields: ["*"],
    });

    if (layerData[key].geometryType === 'point') {
      geoj.featureReduction = user?.clustering === true ? clusterConfig : null;
      if (geoj.featureReduction) {
        geoj.featureReduction.symbol = {
          type: "simple-marker",
          color: layersStyle[key].currentColor
        }
      }
    }
    view.map.layers.removeMany([layerData[key]]);
    view.map.layers.addMany([geoj]);
    setLayerData({
      ...layerData,
      [key]: geoj,
    });
  }

  const setLayerStyle = (key: string, color: RgbaColor, width: number, size: number, lineStyle: string, markerStyle: string, angle: number) => {


    const symbol = layerData[key].renderer.type === "unique-value" ? layerData[key].renderer.defaultSymbol : layerData[key].renderer.symbol;

    symbol.color = color;
    symbol.width = `${width}px`;
    symbol.size = `${size}px`;

    if (markerStyle === 'cross' || markerStyle === 'x') {
      symbol.outline = {
        width: 6,  // points,
        color: color,
      };
    }

    if (lineStyle?.length > 0 && symbol.type === 'simple-line') {
      symbol.style = lineStyle;
    }
    if (markerStyle?.length > 0 && symbol.type === 'simple-marker') {
      symbol.style = markerStyle;
    }
    symbol.angle = angle;
    updateStyle(key, {
      color: color,
      currentColor: color,
      width: `${width}px`,
      currentWidth: width,
      size: `${size}px`,
      currentSize: size,
      style: symbol.style,
      currentLineStyle: symbol.style,
      currentMarkerStyle: symbol.style,
      currentStyle: `symbols-sls-${symbol.style}`,
      angle: angle,
      currentAngle: angle,
      type: symbol.type,
    });
  };

  const createNetworkLayer: any = (store: boolean = true, zoomIn: boolean = false) => {
    const renderer = {
      type: "simple",
      symbol: {
        // type: "simple-line",  // autocasts as new SimpleLineSymbol()
        ...getStyle('line', 'network-layer', 'Network', layersStyle)
      }
    };

    const geoJsonLayer = new GeoJSONLayer({
      id: 'network-layer',
      url: `${BASE_API_URL}/network/sections/geo`,
      popupEnabled: false,
      renderer: renderer,
      spatialReference: { wkid: 3857 },
      outFields: ["*"],
    });

    if (store) {
      setLayerData({
        'network-layer': geoJsonLayer,
      });
    }

    if (zoomIn) {
      geoJsonLayer.on('layerview-create', function () {

        view.goTo(geoJsonLayer.fullExtent)
      });
    }

    return geoJsonLayer;
  };

  useEffect(() => {
    // if custom header (RefreshData) was added when clicking Refresh, remove it
    let hasRefreshData = esriConfig.request.interceptors?.find((interceptedRequest: any) => interceptedRequest?.headers?.hasOwnProperty("RefreshData"));
    if (hasRefreshData) {
      esriConfig.request.interceptors.shift();
    }
  }, [layerData]);

  useEffect(() => {
    const storedStyles = localStorage.getItem('layersStyle');
    const layerRulesStyle = localStorage.getItem('layerRulesStyle');

    if (storedStyles) {
      setLayersStyle(JSON.parse(storedStyles));
    }
    if (layerRulesStyle) {
      setLayerRulesStyles(JSON.parse(layerRulesStyle));
    }

    return () => {
      if (clickEvent !== undefined) {
        clickEvent.remove();
      }
      if (highlight !== undefined) {
        highlight.remove();
      }
    }
  }, []);


  useEffect(() => {
    if (selectedNode === null || selectedNode === undefined) {
      if (searchContent.type === 'current-layer') {
        setSearchContent({
          ...searchContent,
          type: 'network',
          selectedKey: 'network-layer',
          selectedLabel: 'Network',
          selectedCategory: 'Network',
        });
      }
    } else {
      if (searchContent.type === 'current-layer') {
        setSearchContent({
          ...searchContent,
          type: 'current-layer',
          selectedKey: selectedNode.key,
          selectedLabel: selectedNode.title,
          selectedCategory: selectedNode.category,
        });
      }
    }
  }, [selectedNode]);

  // const convert = (lon: any, lat: any) => {
  //   var x = (lon * 20037508.34) / 180;
  //   var y = Math.log(Math.tan(((90 + lat) * Math.PI) / 360)) / (Math.PI / 180);
  //   y = (y * 20037508.34) / 180;
  //   return [x, y];
  // }

  function zoomToBreakCluster(view: any, clusterGraphic: any) {
    const zoomInLevel = view.zoom + 1;

    view.goTo({ target: clusterGraphic.geometry, zoom: zoomInLevel }).then(() => {
      setTimeout(() => {
        view.hitTest({ x: view.width / 2, y: view.height / 2 }).then((response: any) => {
          const cluster = response.results.find((r: any) => r.graphic.attributes && r.graphic.attributes.cluster_count);
          if (cluster) {
            if (cluster.graphic.attributes.cluster_count > 1 && view.zoom < view.constraints.maxZoom) {
              zoomToBreakCluster(view, clusterGraphic);
            }
          }
        });
      }, 300); // Delay time in milliseconds before checking the hitTest after zooming
    });
  }

  useEffect(() => {
    if (baseLayer !== null) {
      const lay = layerData || {};
      if (Object.values(lay).length === 0) {
        view.map = new ArcGISMap({
          layers: [baseLayer, createNetworkLayer(true, true)],
        });
      }

      if (clickEvent !== undefined) {
        clickEvent.remove();
      }

      clickEvent = view.on("click", function (event: any) {
        view.hitTest(event).then(function (response: any) {
          if (response.results.length) {
            const selectedLayer = response.results.filter(function (result: any) {
              return result.graphic.layer !== baseLayer;
            })[0];

            let graphic = selectedLayer.graphic;

            // Check if clicked graphic is a cluster
            if (graphic.attributes.cluster_count && graphic.attributes.cluster_count > 1) {
              zoomToBreakCluster(view, graphic);
              return;
            }

            if (selectedLayer.graphic.attributes) {
              let lat, long = 0;
              if (graphic?.geometry && graphic?.geometry?.getPoint) {


                if (graphic?.geometry?.spatialReference.latestWkid != 4326) {

                  let outSpatialReference = { wkid: 4326 };
                  const geo = projection.project(graphic.geometry, outSpatialReference);
                  const point = geo.getPoint(0, 0);

                  lat = point.latitude;
                  long = point.longitude;
                } else {

                  const point = graphic.geometry.getPoint(0, 0);
                  lat = point.latitude;
                  long = point.longitude;
                }
              }
              setAttributesModalData({
                ...selectedLayer.graphic.attributes,
                latitude: lat,
                longitude: long,
                layerData
              });
              showModal('Attributes');
            }

            view.whenLayerView(graphic.layer).then(function (layerView: any) {
              if (highlight) {
                highlight.remove();
              }
              highlight = layerView.highlight(graphic);
            });
          }
        });
      });
    }
  }, [baseLayer]);



  const onLayerChange = (title: string) => {

    let layerUrl = `https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png?api_key=${process.env.REACT_APP_STADIA_MAPS_API_KEY}`;
    if (title === 'OS MasterMap Grey Scale') {
      layerUrl = "https://api.emapsite.com/dataservicenoauth/api/wmts/xais_2/1.0.0/wMasterMap:gmastermap_schema9/default/EPSG:3857/EPSG:3857:{z}/{y}/{x}.png";
    } else if (title === 'OS Zoomstack') {
      layerUrl = "https://api.emapsite.com/dataservicenoauth/api/wmts/xais/1.0.0/wMasterMap:gmastermap_schema9/default/EPSG:3857/EPSG:3857:{z}/{y}/{x}.png";
    } else if (title === 'StadiaMaps') {
      layerUrl = `https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png?api_key=${process.env.REACT_APP_STADIA_MAPS_API_KEY}`;
    }
    var wmsLayer = new WebTileLayer({
      // spatialReference: spatRef,
      name: title,
      title: title,
      urlTemplate: layerUrl,
      visible: true
    });
    view.map.layers.removeAt(0);
    view.map.layers.add(wmsLayer, 0);
    setBaseLayer(wmsLayer);
    setCurrentLayer(title);
  }


  useEffect(() => {
    if (!view) return;

    const updateLayersBasedOnZoom = () => {
      const zoomLevel = view.zoom;
      if (zoomLevel >= 18) {
        setZoomStackIsAllowed(true);
        if(zoomLevel === MAX_ZOOM ){
          setIsClusteringEnabled(false)
        } else if(zoomLevel < MAX_ZOOM) {
          if(user?.clustering === true){
            setIsClusteringEnabled(true)
          }
        }
      } else if (zoomLevel < 18 && zoomLevel > -1) {
        setZoomStackIsAllowed(false);
        // Check if the current layer is not already 'StadiaMaps' before changing
        if (view.map.layers.length === 0 || view.map.layers.getItemAt(0).name !== 'StadiaMaps') {
          onLayerChange('StadiaMaps');
        }
      }
    };
    const zoomWatcher = view.watch('zoom', updateLayersBasedOnZoom);

    return () => {
      zoomWatcher.remove();
    };
  }, [view, user?.clustering]);

  useEffect(() => {
    Object.values(layerData).forEach((layer: any) => {
      if (layer.geometryType === 'point') {
        // Update the featureReduction based on isClusteringEnabled state
        layer.featureReduction = isClusteringEnabled ? clusterConfig : null;
      }
    });
  }, [isClusteringEnabled, layerData]);


  const getLayerColor = (category: string) => {
    switch (category) {
      case ("Assets"):
        return blue;
      case ("Forward Works Programme"):
        return orange;
      case ("Treatment Lengths"):
        return purple;
      case ("Hotspots"):
        return yellow;
      case ("Accidents"):
        return purple;
      case ("Network"):
        return black;
    }
    return red;
  }

  const updateStyle = (key: string, style: any) => {
    setLayersStyle((prevState: any) => {
      const updatedState = { ...prevState, [key]: style }
      localStorage.setItem('layersStyle', JSON.stringify(updatedState))
      return updatedState;
    });
  }

  const updateLayerRulesStyle = (key: string, url: string, valueExpression: string, layerRules: any[]) => {
    setLayerRulesStyles((prevState: any) => {
      const updatedState = { ...prevState, [key]: { layerRules, url, expression: valueExpression } };
      localStorage.setItem('layerRulesStyle', JSON.stringify(updatedState))
      return updatedState;
    });
  }




  const getStyle = (geometryType: 'line' | 'point', key: string, category: string, from: any) => {


    if (from[key]) {
      if (from[key].style === 'cross' || from[key].style === 'x') {
        return {
          ...from[key],
          outline: {
            width: 6,  // points,
            color: from[key].currentColor
          }
        }
      }
      return from[key];
    } else if (geometryType === 'point') {
      const style = {
        color: getLayerColor(category),
        size: "22px",
        style: "circle",
        currentStyle: "symbols-sls-circle",
        type: "simple-marker",
        angle: 0,
        currentWidth: 4,
        currentSize: 22,
        currentLineStyle: 'solid',
        currentMarkerStyle: 'circle',
        currentColor: getLayerColor(category),
        currentAngle: 0,
      }

      updateStyle(key, style);
      return style;
    } else {
      const style = {
        color: getLayerColor(category),
        width: "4px",
        cap: "square",
        join: "bevel",
        style: "solid",
        currentStyle: "symbols-sls-solid",
        type: "simple-line",
        currentWidth: 4,
        currentSize: 22,
        currentLineStyle: 'solid',
        currentMarkerStyle: 'circle',
        currentColor: getLayerColor(category),
        currentAngle: 0,
      }
      updateStyle(key, style);
      return style;
    }
  }

  useEffect(() => {
    Object.values(layerData).forEach((layer: any) => {
      if (layer.geometryType === 'point') {
        layer.featureReduction = user?.clustering ? clusterConfig : null;
        if (layer.featureReduction) {
          layer.featureReduction.symbol = {
            type: "simple-marker",
            color: layersStyle[layer.id].currentColor
          }
        }
      }
    });

  }, [user, layerData]);

  useEffect(() => {
    Object.values(layerData).forEach((layer: any) => {
      if (layer.geometryType === 'point') {
        if (layer.featureReduction) {
          layer.featureReduction.symbol = {
            type: "simple-marker",
            color: layersStyle[layer.id].currentColor
          }
        }
      }
    });

  }, [layersStyle])

  const handleContextMenuRefreshClicked = (selectedKeys: Key[]) => {
    const newLayerData = {...layerData};
    const newLayers: any[] = [];
    let isNetworkLayerSelected = checkedNodesState.find((node: {
      key: string;
    }) => node.key === 'network-layer' && selectedKeys[0] === 'network-layer');
    let geoJsonLayer: any = null;
    if (isNetworkLayerSelected) {
      geoJsonLayer = createNetworkLayer(false);
    } else {
      const node = checkedNodesState[0];
      const currentLayerRules = layerRulesStyles[node.key];
      geoJsonLayer = new GeoJSONLayer({
        id: node.key,
        url: currentLayerRules?.url || `${process.env.REACT_APP_API_URL}/${node.endPoint}`,
        popupEnabled: false,
        spatialReference: {wkid: 3857},
        outFields: ["*"],
      });
      geoJsonLayer.when(function () {
        const uniqueRendered = currentLayerRules?.layerRules;
        if (geoJsonLayer.geometryType === 'point') {
          if (uniqueRendered) {
            const renderer = {
              type: "unique-value",
              defaultSymbol: {
                ...getStyle('point', node.key, node.category, layersStyle)
              },
              valueExpression: currentLayerRules?.expression,
              uniqueValueInfos: uniqueRendered.map((us: any) => ({
                value: us.dataType === "Integer" ? us.label : `${us.name}-${us.value}`, symbol: {
                  ...us.style
                }
              }))
            };
            geoJsonLayer.renderer = renderer;
          } else {
            const renderer = {
              type: "simple",
              symbol: {
                // type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
                ...getStyle('point', node.key, node.category, layersStyle)
              }
            };
            geoJsonLayer.renderer = renderer;
          }
        } else {
          if (uniqueRendered) {
            const renderer = {
              type: "unique-value",
              defaultSymbol: {
                ...getStyle('line', node.key, node.category, layersStyle)
              },
              valueExpression: currentLayerRules?.expression,
              uniqueValueInfos: uniqueRendered.map((us: any) => ({
                value: us.dataType === "Integer" ? us.label : `${us.name}-${us.value}`, symbol: {
                  ...us.style
                }
              }))
            };
            geoJsonLayer.renderer = renderer;
          } else {
            const renderer = {
              type: "simple",
              symbol: {
                // type: "simple-line",  // autocasts as new SimpleLineSymbol()
                ...getStyle('line', node.key, node.category, layersStyle)
              }
            };
            geoJsonLayer.renderer = renderer;
          }
        }
      }).catch((e: any) => console.log("error: ", e));
    }
    let selectedNodeKey = checkedNodesState.find((node: {
      key: React.Key;
    }) => node.key === selectedKeys[0]).key;
    newLayerData[selectedNodeKey] = geoJsonLayer;
    const existingLayer = view.map.layers.find((layer: { id: any; }) => layer.id === selectedNodeKey);
    newLayers.push(geoJsonLayer);
    if (existingLayer) {
      // add RefreshData header to new request
      esriConfig.request.interceptors.unshift({
        urls: getUrls(),
        headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`, "RefreshData": true}
      });
      existingLayer.renderer = newLayers[0].renderer; // Update renderer as an example
      refreshAndWaitForAPI(existingLayer);
    }
  }

  async function refreshAndWaitForAPI(layer: { refresh?: any; refreshTimestamp: any; }) {
    layer.refresh();
    //@ts-ignore
    await waitForRefreshTimestamp(layer);
  }

  function setLayerRefreshLoadingState(layerId: { toString: () => any; }, shouldRefreshLoadingIconBeVisible: boolean) {
    // @ts-ignore
    setLayerRefreshLoading((prevPairs) => {
      return ({
        ...prevPairs,
        [layerId.toString()]: shouldRefreshLoadingIconBeVisible,
      });
    });
  }

  function waitForRefreshTimestamp(layer: {
    refreshTimestamp: null,
    refreshParameters: null,
    refreshInterval: null,
  }) {
    // @ts-ignore
    const layerId = layer.id;
    return new Promise((resolve) => {
      const checkTimestamp = () => {
        if (isFirstRefresh) {
          if (layer.refreshTimestamp !== null && layer.refreshTimestamp !== 0) {
            setLayerRefreshLoadingState(layerId, false);
            setIsFirstRefresh(false);
            if (layer.refreshTimestamp !== 0) setSavedTimestampWhenRefreshingLayer(layer.refreshTimestamp);
            //@ts-ignore
            resolve();
          } else {
            setTimeout(checkTimestamp, 100);
          }
        } else {
          if (layer.refreshTimestamp !== null && savedTimestampWhenRefreshingLayer < layer.refreshTimestamp) {
            setLayerRefreshLoadingState(layerId, false);
            if (savedTimestampWhenRefreshingLayer < layer.refreshTimestamp) setSavedTimestampWhenRefreshingLayer(layer.refreshTimestamp);
            //@ts-ignore
            resolve();
          } else {
            setTimeout(checkTimestamp, 100);
          }
        }
      };
      checkTimestamp();
    });
  }

  const onNodeChecked = (checkedNodes: Array<any>, currentSelectedNode?: any) => {
    setCheckedNodesState((prevCheckedNodes: any) => [...prevCheckedNodes, checkedNodes[0]]);

    if (currentSelectedNode !== null && currentSelectedNode !== undefined) {
      setSelectedNode(currentSelectedNode);
    }
    const newLayerData = { ...layerData };

    const newLayers: any[] = [];
    const removedLayers: any[] = [];

    checkedNodes.forEach((node: any) => {

      if (newLayerData[node.key] !== undefined) {
        removedLayers.push(newLayerData[node.key])
        delete (newLayerData[node.key]);
        return;
      }

      let geoJsonLayer: any = null;

      if (node.title === 'Network') {
        geoJsonLayer = createNetworkLayer(false);
      } else {
        const currentLayerRules = layerRulesStyles[node.key];
        geoJsonLayer = new GeoJSONLayer({
          id: node.key,
          url: currentLayerRules?.url || `${process.env.REACT_APP_API_URL}/${node.endPoint}`,
          popupEnabled: false,
          spatialReference: { wkid: 3857 },
          outFields: ["*"],
        });

        const layerId = geoJsonLayer.id;
        setLayerRefreshLoadingState(layerId, true);

        geoJsonLayer.when(function () {
          const layerId = geoJsonLayer.id;
          setLayerRefreshLoadingState(layerId, false);
          const uniqueRendered = currentLayerRules?.layerRules;
          if (geoJsonLayer.geometryType === 'point') {
            if (uniqueRendered) {
              const renderer = {
                type: "unique-value",
                defaultSymbol: {
                  ...getStyle('point', node.key, node.category, layersStyle)
                },
                valueExpression: currentLayerRules?.expression,
                uniqueValueInfos: uniqueRendered.map((us: any) => ({
                  value: us.dataType === "Integer" ? us.label : `${us.name}-${us.value}`, symbol: {
                    ...us.style
                  }
                }))
              };

              geoJsonLayer.renderer = renderer;
            } else {
              const renderer = {
                type: "simple",
                symbol: {
                  ...getStyle('point', node.key, node.category, layersStyle)
                }
              };
              geoJsonLayer.renderer = renderer;
            }
            geoJsonLayer.featureReduction = user?.clustering === true ? clusterConfig : null;
            if (geoJsonLayer.featureReduction) {
              geoJsonLayer.featureReduction.symbol = {
                type: "simple-marker",
                color: layersStyle?.[node.key]?.currentColor ??
                  geoJsonLayer.renderer?.symbol?.color ??
                  geoJsonLayer.renderer?.defaultSymbol?.color ??
                  "red"
              };
            }

          } else {
            if (uniqueRendered) {
              const renderer = {
                type: "unique-value",
                defaultSymbol: {
                  ...getStyle('line', node.key, node.category, layersStyle)
                },
                valueExpression: currentLayerRules?.expression,
                uniqueValueInfos: uniqueRendered.map((us: any) => ({
                  value: us.dataType === "Integer" ? us.label : `${us.name}-${us.value}`, symbol: {
                    ...us.style
                  }
                }))
              };
              geoJsonLayer.renderer = renderer;
            } else {
              const renderer = {
                type: "simple",
                symbol: {
                  // type: "simple-line",  // autocasts as new SimpleLineSymbol()
                  ...getStyle('line', node.key, node.category, layersStyle)
                }
              };
              geoJsonLayer.renderer = renderer;
            }

          }
        }).catch(() => {
          // if the creation of the layer is not successful, remove the loading icon
          setLayerRefreshLoadingState(layerId, false);
        });
      }

      newLayerData[node.key] = geoJsonLayer;
      newLayers.push(geoJsonLayer);
    });

    view.map.layers.addMany(newLayers);
    view.map.layers.removeMany(removedLayers);
    setLayerData(newLayerData);
  };

  const bringLayerToTop = (layerKey: string) => {
    if (layerData[layerKey]) {
      view.map.layers.reorder(layerData[layerKey], view.map.layers.length - 1);
    }
  };

  return (
    <div className="dashboard-page">
      {modalState['Major asset group overview'] && (
        <Charting
          isOpen={modalState['Major asset group overview']}
          closeModal={onCloseModal}
        />
      )}
      {modalState['Attributes'] && (
        <Attributes
          isOpen={modalState['Attributes']}
          data={attributesModalData}
          closeModal={onCloseAttributesModal}
        />
      )}
      <NavBar
        displayModal={showModal}
        isExpanded={primaryIsExpanded}
        setIsExpanded={setPrimaryIsExpanded}
      />
      <div className="xa-map-container">
        <MapView setView={setView} setBaseLayer={setBaseLayer} />
      </div>
      <ToolBar
        onAction={onAction}
        activeActions={actionsState}
        isExpanded={secondaryIsExpanded}
        setIsExpanded={setSecondaryIsExpanded}
        currentLayer={currentLayer}
        onLayerChange={onLayerChange}
        onNodeChecked={onNodeChecked}
        setLayerStyle={setLayerStyle}
        setLayerVisualisationRules={setLayerVisualisationRules}
        bringLayerToTop={bringLayerToTop}
        zoomToSection={zoomToSection}
        layersStyle={layersStyle}
        layerRulesStyles={layerRulesStyles}
        zoomStackIsAllowed={zoomStackIsAllowed}
        setCheckedNodesState={setCheckedNodesState}
        handleContextMenuRefreshClicked={handleContextMenuRefreshClicked}
        checkedNodesState={checkedNodesState}
        layerRefreshLoading={layerRefreshLoading}
        setLayerRefreshLoading={setLayerRefreshLoading}
      />
      <SearchContainer
        isNodeSelected={isNodeSelected}
        searchContentData={searchContent}
        onSelectSearchDetailsItem={onSelectSearchDetailsItem}
        onSelectSearchDetailsType={onSelectSearchDetailsType}
      />
      <UserContainer
        email={user.email}
        firstname={user.firstname}
        surname={user.surname}
        navbarExpanded={primaryIsExpanded}
      />
    </div>
  );
}

export default Dashboard;
