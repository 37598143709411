/* eslint-disable arrow-parens */
/* eslint-disable prefer-destructuring */
import axios from 'axios';
import { AxiosRequestConfig } from 'axios';

const BASE_API_URL = `${process.env.REACT_APP_API_URL}/api`;

const instance = axios.create({
  baseURL: BASE_API_URL,
});
const parseErrors = (error: any) => error;

const setHeader = (headerName: string, headerValue: string) => {
  instance.defaults.headers.common[headerName] = headerValue;
};

const removeHeader = (headerName: string) => {
  delete instance.defaults.headers.common[headerName];
};

const getHeader = (headerName: string) => instance.defaults.headers.common[headerName];

const post = async<T, D>(url: string, data: T, config?: AxiosRequestConfig<T>) => {
  try {
    const response = await instance.post<D>(url, data, config || {});
    return response;
  } catch (error) {
    throw (parseErrors(error));
  }
};

const put = async<T, D>(url: string, data: T) => {
  try {
    const response = await instance.put<D>(url, data);
    return response;
  } catch (error) {
    throw (parseErrors(error));
  }
};

const patch = async<T, D>(url: string, data: T) => {
  try {
    const response = await instance.patch<D>(url, data);
    return response;
  } catch (error) {
    throw (parseErrors(error));
  }
};

const get = async<D>(url: string, config?: AxiosRequestConfig<any>) => {
  try {
    const response = await instance.get<D>(url, config || {});
    return response;
  } catch (error) {
    throw (parseErrors(error));
  }
};

const del = async (url: string) => {
  try {
    const response = await instance.delete(url);
    return response;
  } catch (error) {
    throw (parseErrors(error));
  }
};

const delWithBody = async<T>(url: string, body: T) => {
  try {
    const response = await instance.delete(url, { data: body });
    return response;
  } catch (error) {
    throw (parseErrors(error));
  }
};

export {
  instance,
  post,
  patch,
  put,
  get,
  del,
  delWithBody,
  setHeader,
  getHeader,
  removeHeader,
};
