import dayjs from 'dayjs';
import { get } from 'utils/request';
import { getRefreshDataHeader } from "../utils/getRefreshDataHeader";

export interface SchemeInfo {
  id: number,
  parameterSetId: number,
  year: number,
  sectionLabel: string,
  startChainage: number,
  endSectionLabel: string,
  endChainage: number,
  description: string,
  xsp: string,
  length: number,
  schemeType: string,
  averageCI: number,
  ratingValue: number,
  inefficientLength: number,
  efficiency: number,
  agreed: boolean,
  funded: boolean,
  cIPriority: number,
  creationDate: string,
  contributors: string,
  source: string,
  vMScore: number,
  vMInspectionDate: string,
  vMComments: string,
  area: number,
  missingInventory: boolean,
  cost: string,
}

export interface TreatmentInfo {
  id: number,
  parameterSetId: number,
  schemeId: number,
  order: number,
  cost: number,
  life: number,
  lanes: number,
  implementationYear: number,
  economicRanking: number,
  funded: boolean,
  creationDate: string;
}

export interface FwpInfo {
  id: number,
  schemeId: number,
  schemeType: string,
  implementationYear: number,
  sectionLabel: string,
  startChainage: number,
  endChainage: number,
  xsp: string,
  length: number,
  treatmentDescription: string,
  life: number,
  lanesTreated: number,
  cost: string,
  initialTreatment: boolean,
  inflatedCost: boolean,
  treatmentPlanId: number,
}

const formatCostInResponse = (responseData: { cost: string; }) => {
  const costNumber = Number(responseData.cost);
  const hasDecimal = costNumber % 1 !== 0; // Check if there are decimal places

  responseData.cost = "£" + new Intl.NumberFormat('en-GB', {
    minimumFractionDigits: hasDecimal ? 2 : 0,
    maximumFractionDigits: 2
  }).format(costNumber);
}

const schemeActions = () => {

  const getProgrammedSchemeDetails = async (data: { id: any }, refreshData: boolean = false): Promise<SchemeInfo> => {
    const response = await get<SchemeInfo>(`schemes/programmed/${data?.id}`, getRefreshDataHeader(refreshData));
    const responseData = response?.data;
    if (responseData.creationDate) responseData.creationDate = dayjs(responseData?.creationDate).format('YYYY-MM-DD');
    if (responseData.vMInspectionDate) responseData.vMInspectionDate = dayjs(responseData?.vMInspectionDate).format('YYYY-MM-DD');
    if (responseData.cost) formatCostInResponse(responseData);
    return response?.data;
  }

  const getSchemeDetails = async (data: { id: any }, refreshData: boolean = false): Promise<SchemeInfo> => {
    const response = await get<SchemeInfo>(`schemes/${data?.id}`, getRefreshDataHeader(refreshData));
    const responseData = response?.data;
    if (responseData.creationDate) responseData.creationDate = dayjs(responseData?.creationDate).format('YYYY-MM-DD');
    if (responseData.vMInspectionDate) responseData.vMInspectionDate = dayjs(responseData?.vMInspectionDate).format('YYYY-MM-DD');
    if (responseData.cost) formatCostInResponse(responseData);
    return response?.data;
  }

  const getFwpDetails = async (fwpId: string, refreshData: boolean = false): Promise<FwpInfo> => {
    const response = await get<FwpInfo>(`schemes/fwps/${fwpId}`, getRefreshDataHeader(refreshData));
    const responseData = response?.data;
    if (responseData.cost) {
      formatCostInResponse(responseData);
    }
    return response?.data;
  }

  const getTreatmentDetails = async (treatmentId: string, refreshData: boolean = false): Promise<TreatmentInfo> => {
    const response = await get<TreatmentInfo>(`schemes/treatments/${treatmentId}`, getRefreshDataHeader(refreshData));
    const responseData = response.data;
    if (responseData.creationDate) responseData.creationDate = dayjs(responseData?.creationDate).format('YYYY-MM-DD HH:mm');
    return response.data;
  }

  return {
    getSchemeDetails,
    getFwpDetails,
    getTreatmentDetails,
    getProgrammedSchemeDetails
  };
};

export { schemeActions };
