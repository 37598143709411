import { useEffect } from 'react';
import './style.scss';

interface Props {
  currentLayer: string,
  onLayerChange: (title: string) => void,
  zoomStackIsAllowed: boolean
}

const Layers: React.FC<Props> = ({
  currentLayer = "",
  onLayerChange,
  zoomStackIsAllowed
}) => {
  const layerSelected = (ev: any) => {
    onLayerChange(ev.target.value);
  }

  return (
    <div className="xa-layers-container">
      {/* <div className="xa-layer-item">
        <input
          type="radio"
          name="default-radio"
          id="default-radio-1"
          value="OpenStreetMap"
          checked={currentLayer === "OpenStreetMap"}
          onChange={layerSelected}
          className="xa-radio-input"
        />
        <label
          htmlFor="default-radio-1"
          className="xa-radio-label"
        >
          OpenStreetMap
        </label>
      </div> */}
      <div className="xa-layer-item">
        <input
          type="radio"
          name="default-radio"
          id="default-radio-1"
          value="StadiaMaps"
          checked={currentLayer === "StadiaMaps"}
          onChange={layerSelected}
          className="xa-radio-input"
        />
        <label
          htmlFor="default-radio-1"
          className="xa-radio-label"
        >
          StadiaMaps
        </label>
      </div>
      <div className="xa-layer-item">
        <input
          type="radio"
          name="default-radio"
          id="default-radio-2"
          value="OS Zoomstack"
          checked={currentLayer === "OS Zoomstack"}
          onChange={layerSelected}
          className="xa-radio-input"
          disabled={!zoomStackIsAllowed}
        />
        <label
          htmlFor="default-radio-2"
          className="xa-radio-label"
        >
          OS Zoomstack
        </label>
      </div>
      <div className="xa-layer-item">
        <input
          type="radio"
          name="default-radio"
          id="default-radio-3"
          value="OS MasterMap Grey Scale"
          checked={currentLayer === "OS MasterMap Grey Scale"}
          onChange={layerSelected}
          className="xa-radio-input"
          disabled={!zoomStackIsAllowed}
        />
        <label
          htmlFor="default-radio-3"
          className="xa-radio-label"
        >
          OS MasterMap Grey Scale
        </label>
      </div>
    </div>
  );
}
export default Layers;
