import Modal from 'react-modal';
import { useEffect, useState } from 'react';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { assetActions, AssetTypeAttributesInfo } from 'actions/asset';
import renderType from './lineTypesRenderer';
import renderMarkerType from './makerTypesRenderer';
import {
  MdClose, MdEdit, MdKeyboardArrowUp, MdKeyboardArrowDown,
  MdAddCircle, MdRemoveCircle
} from 'react-icons/md';
import { TbArrowsMinimize, TbArrowsMaximize } from 'react-icons/tb';
import { CgArrowLongRight } from 'react-icons/cg';
import { FaTimes } from 'react-icons/fa';
import Draggable from 'react-draggable';
import InputNumber from 'rc-input-number';
import Select, { SingleValue } from 'react-select';
import { SymbologyModel } from 'models/SymbologyModel';
import { RgbaColor } from "react-colorful";
import Symbology from './symbology';
import { LayerAttributeStyle } from 'models/LayerAttributeStyle';
import { LayerRuleStyle, LayerRuleStypeElement } from 'models/LayerRuleStyle';
import AutoCategoriseRules from './autoCategoriseRules';
import ConfirmModal from 'components/ConfirmModal';

const getRuleName = (la: LayerRuleStypeElement): string => {
  let ruleName = `${la.name} ${la.firstComparison?.value} ${la.firstValue}`;
  if (la?.ruleChaining?.label && la?.secondComparison?.label && la?.secondValue) {
    ruleName += `${la.ruleChaining.value === "Or" ? " Or" : " And"} ${la.secondComparison?.value} ${la.secondValue}`;
  }
  return ruleName
}

const gtoreq = {
  value: '>=',
  label: 'Greater Than or Equal To',
},
  gt = {
    value: '>',
    label: 'Greater Than',
  },
  eq = {
    value: '==',
    label: 'Equal To',
  },
  noteq = {
    value: '!=',
    label: 'Not Equal To',
  },
  ltoreq = {
    value: '<=',
    label: 'Lesser Than or Equal To',
  },
  lt = {
    value: '<',
    label: 'Lesser Than',
  };

const and = {
  value: 'And',
  label: 'And',
};
const or = {
  value: 'Or',
  label: 'Or',
};

const comparisonOptions = [gtoreq, gt, eq, noteq, ltoreq, lt];

const ruleChainingOptions = [and, or];

const getIntervalPart = (comparison: string, value: number, step: number): [number | null, number | null][] => {
  switch (comparison) {
    case gtoreq.value:
      return [[value, null]];
    case gt.value:
      return [[value + step, null]];
    case ltoreq.value:
      return [[null, value]];
    case lt.value:
      return [[null, value - step]];
    case eq.value:
      return [[value, value]];
    case noteq.value:
      return [[null, value - step], [value + step, null]];
    default:
      return [[null, null]];
  }
}

const getInterval = (firstComparison: string | undefined, firstValue: number | null, secondComparison: string | undefined,
  secondValue: number | null, ruleChaining: string | undefined, min: number, max: number, step: number): [number, number][] => {
  if (secondComparison === undefined
    || secondValue === null
    || ruleChaining === undefined) {
    const interval = getIntervalPart(firstComparison || '', firstValue || 0, step);
    return (interval || []).map(x => {
      const a = x[0] === null ? min : x[0];
      const b = x[1] === null ? max : x[1];
      return [a, b];
    });
  }

  if (ruleChaining === or.value) {
    const leftInterval: [number, number][] = getIntervalPart(firstComparison || '', firstValue || 0, step).map(x => {
      const a = x[0] === null ? min : x[0];
      const b = x[1] === null ? max : x[1];
      return [a, b];
    });
    const rightInterval: [number, number][] = getIntervalPart(secondComparison || '', secondValue || 0, step).map(x => {
      const a = x[0] === null ? min : x[0];
      const b = x[1] === null ? max : x[1];
      return [a, b];
    });
    return [...(leftInterval || []), ...(rightInterval || [])];
  } else {
    const leftInterval: [number | null, number | null][] = getIntervalPart(firstComparison || '', firstValue || 0, step);
    const rightInterval: [number | null, number | null][] = getIntervalPart(secondComparison || '', secondValue || 0, step);

    if (firstComparison === noteq.value) {
      return leftInterval.map(x => {
        const minValue = rightInterval[0][0] === null ? min : rightInterval[0][0];
        const maxValue = rightInterval[0][1] === null ? max : rightInterval[0][1];
        const a = x[0] === null ? minValue : x[0];
        const b = x[1] === null ? maxValue : x[1];
        return [a, b];
      });
    }

    if (secondComparison === noteq.value) {
      return rightInterval.map(x => {
        const minValue = leftInterval[0][0] === null ? min : leftInterval[0][0];
        const maxValue = leftInterval[0][1] === null ? max : leftInterval[0][1];
        const a = x[0] === null ? minValue : x[0];
        const b = x[1] === null ? maxValue : x[1];
        return [a, b];
      });
    }

    const minValue = leftInterval[0][0] === null ? rightInterval[0][0] : leftInterval[0][0];
    const maxValue = leftInterval[0][1] === null ? rightInterval[0][1] : leftInterval[0][1];

    return [[minValue || min, maxValue || max]];
  }
}

const countDecimals = (nr: number): number => {
  if (Math.floor(nr.valueOf()) === nr.valueOf()) {
    return 0;
  }
  return nr.toString().split(".")[1].length || 0;
}

const validateNumericRule = (firstComparison: string | undefined, firstValue: number | null, secondComparison: string | undefined,
  secondValue: number | null, ruleChaining: string | undefined, min: number, max: number, displaySecondRule: boolean): boolean => {
  if (ruleChaining === or.value) {
    return true;
  }

  if ([gtoreq.value, gt.value].includes(firstComparison || '') &&
    [gtoreq.value, gt.value].includes(secondComparison || '') && displaySecondRule) {
    return false;
  }

  if ([ltoreq.value, lt.value].includes(firstComparison || '') &&
    [ltoreq.value, lt.value].includes(secondComparison || '') && displaySecondRule) {
    return false;
  }

  if (firstComparison === noteq.value && secondComparison === eq.value && displaySecondRule) {
    return false;
  }

  if (firstComparison === eq.value && secondComparison === noteq.value && displaySecondRule) {
    return false;
  }

  if ([gtoreq.value, gt.value, ltoreq.value, lt.value].includes(firstComparison || '') &&
    secondComparison === eq.value && displaySecondRule) {
    return false;
  }

  if ([gtoreq.value, gt.value, ltoreq.value, lt.value].includes(secondComparison || '') &&
    firstComparison === eq.value && displaySecondRule) {
    return false;
  }

  let decimals = Math.max(countDecimals(min), countDecimals(max));
  let step = 1;
  if (decimals >= 1) {
    step = Number.parseFloat("0." + "0".repeat(decimals - 1) + "1");
  }

  const intervals = getInterval(firstComparison, firstValue, displaySecondRule ? secondComparison : undefined,
    displaySecondRule ? secondValue : null, displaySecondRule ? ruleChaining : undefined, min, max, step);

  const a = (intervals || []).some(x => x[0] > x[1]);

  return !(intervals || []).some(x => x[0] > x[1]);
}

const validateIntervals = (otherRules: LayerRuleStypeElement[], firstComparison: string | undefined, firstValue: number | null, secondComparison: string | undefined,
  secondValue: number | null, ruleChaining: string | undefined, min: number, max: number): string => {
  let decimals = Math.max(countDecimals(min), countDecimals(max));
  let step = 1;
  if (decimals >= 1) {
    step = Number.parseFloat("0." + "0".repeat(decimals - 1) + "1");
  }

  const intervals = getInterval(firstComparison, firstValue, secondComparison, secondValue, ruleChaining, min, max, step);

  if ((otherRules.filter(x => x.firstValue !== null) || []).length === 0) {
    return "";
  }

  let error = "";


  otherRules.filter(x => x.firstValue !== null).forEach(x => {
    const currentInterval = getInterval(x.firstComparison?.value, x.firstValue, x.secondComparison?.value, x.secondValue,
      x.ruleChaining?.value, min, max, step);
    const overlaps = currentInterval.some(y => {
      return intervals.some(z => {
        return (z[0] >= y[0] && z[0] <= y[1])
          || (y[0] >= z[0] && y[0] <= z[1])
          || (z[1] >= y[0] && z[1] <= y[1])
          || (y[1] >= z[0] && y[1] <= z[1]);
      })
    });
    if (overlaps) {
      error += `${getRuleName(x)}, `;
    }
  });

  if (error.length > 0) {
    return `Rule overlaps with ${error}`;
  }

  return "";
}

interface VisualisationRulesProps {
  isOpen: boolean,
  closeModal: () => void,
  itemKey: string,
  category: string,
  title: string,
  layerType?: string,
  defaultStyle: SymbologyModel,
  layerRulesStyles: LayerRuleStyle,
  setLayerVisualisationRules: (key: string, type: string, style: SymbologyModel, layerAttributeStyles: LayerAttributeStyle[]) => void,
  parameterId: number
};

interface ScaleLineProps {
  min: number,
  max: number,
};


interface VisualisationRuleProps {
  isOpen: boolean,
  closeModal: () => void,
  itemKey: string,
  category: string,
  title: string,
  layerType?: string,
  defaultStyle: SymbologyModel,
  layerRuleStypeElement: LayerRuleStypeElement | null,
  setLayerVisualisationRule: (layerAttributeStyles: LayerRuleStypeElement) => void,
  otherRules: LayerRuleStypeElement[],
  parameterId: number
};

interface NumericVisualisationRulesProps {
  setFirstComparison: React.Dispatch<React.SetStateAction<SingleValue<{
    label: string;
    value: string;
  }>>>,
  setSecondComparison: React.Dispatch<React.SetStateAction<SingleValue<{
    label: string;
    value: string;
  }>>>,
  setRuleChaining: React.Dispatch<React.SetStateAction<SingleValue<{
    label: string;
    value: string;
  }>>>,
  firstComparison: SingleValue<{
    label: string;
    value: string;
  }>,
  secondComparison: SingleValue<{
    label: string;
    value: string;
  }>,
  ruleChaining: SingleValue<{
    label: string;
    value: string;
  }>,
  setFirstValue: React.Dispatch<React.SetStateAction<number | null>>,
  setSecondValue: React.Dispatch<React.SetStateAction<number | null>>,
  firstValue: number | null,
  secondValue: number | null,
  displaySecondRule: boolean,
  setDisplaySecondRule: React.Dispatch<React.SetStateAction<boolean>>,
  minValue: number,
  maxValue: number,
  isInvalid: boolean,
  error: string,
};

const ScaleLine: React.FC<ScaleLineProps> = ({
  min = 0,
  max = 0,
}) => (
  <div className="xa-scale">
    <div className="xa-scale-line"></div>
    <div className="xa-scale-values">
      <span>{min}</span>
      <span>{max}</span>
    </div>
  </div>
);

const NumericVisualisationRules: React.FC<NumericVisualisationRulesProps> = ({
  firstComparison,
  setFirstComparison,
  secondComparison,
  setSecondComparison,
  ruleChaining,
  setRuleChaining,
  firstValue,
  setFirstValue,
  secondValue,
  setSecondValue,
  displaySecondRule,
  setDisplaySecondRule,
  minValue,
  maxValue,
  isInvalid,
  error
}) => {
  const toggleDisplaySecondRule = () => setDisplaySecondRule(prevState => !prevState);
  const [step, setStep] = useState(0);

  const onFirstComparisonChange = (newVal: SingleValue<{
    label: string,
    value: string,
  }>) => {
    setFirstComparison(newVal);
  }

  const onSecondComparisonChange = (newVal: SingleValue<{
    label: string,
    value: string,
  }>) => {
    setSecondComparison(newVal);
  }

  const onRuleChainingChange = (newVal: SingleValue<{
    label: string,
    value: string,
  }>) => {
    setRuleChaining(newVal);
  }

  useEffect(() => {
    if (!displaySecondRule) {
      setRuleChaining(ruleChainingOptions[0]);
      setSecondComparison(comparisonOptions[0]);
      setSecondValue(minValue);
    }
  }, [displaySecondRule]);

  useEffect(() => {
    let decimals = Math.max(countDecimals(minValue), countDecimals(maxValue));
    let step = 1;
    if (decimals >= 1) {
      step = Number.parseFloat("0." + "0".repeat(decimals - 1) + "1");
    }
    setStep(step);
  }, [minValue, maxValue])

  return (
    <>
      <ScaleLine
        min={minValue}
        max={maxValue}
      />
      <div className="xa-form-field inline">
        <Select
          options={comparisonOptions}
          onChange={onFirstComparisonChange}
          value={firstComparison}
          className={'custom_select'}
          menuPlacement="bottom"
          classNamePrefix="custom_select"
        />
        <InputNumber
          min={minValue}
          max={maxValue}
          step={step}
          value={firstValue}
          onChange={setFirstValue}
        />
        <button className={`xa-symbology-add${displaySecondRule ? ' xa-symbology-disabled' : ''}`} onClick={toggleDisplaySecondRule} disabled={displaySecondRule}>
          <MdAddCircle />
        </button>
      </div>
      {displaySecondRule &&
        (
          <>
            <div className="xa-form-field small">
              <Select
                options={ruleChainingOptions}
                onChange={onRuleChainingChange}
                value={ruleChaining}
                className={'custom_select'}
                menuPlacement="bottom"
                classNamePrefix="custom_select"
              />
            </div>
            <div className="xa-form-field inline">
              <Select
                options={comparisonOptions}
                onChange={onSecondComparisonChange}
                value={secondComparison}
                className={'custom_select'}
                menuPlacement="bottom"
                classNamePrefix="custom_select"
              />
              <InputNumber
                min={minValue}
                max={maxValue}
                step={step}
                value={secondValue}
                onChange={setSecondValue}
              />
              <button className="xa-symbology-remove" onClick={toggleDisplaySecondRule}>
                <MdRemoveCircle />
              </button>
            </div>
          </>
        )}
      {isInvalid &&
        (
          <div className="xa-form-field error">
            Plese check the values of your visualisation rule
          </div>
        )}
      {error.length > 0 &&
        (
          <div className="xa-form-field error">
            {error}
          </div>
        )}
    </>
  );
};

const VisualisationRules: React.FC<VisualisationRulesProps> = ({
  isOpen = false,
  closeModal = () => { },
  itemKey,
  layerType = 'both',
  category,
  title,
  defaultStyle,
  layerRulesStyles,
  setLayerVisualisationRules,
  parameterId
}) => {

  const [isModalMinimized, setModalMinimized] = useState<boolean>(false);
  const toggleModalState = () => setModalMinimized(prevState => !prevState);
  const [visRulesOpened, setVisRulesOpened] = useState(false);
  const [autoCategoriseRulesOpened, setAutoCategoriseRulesOpened] = useState(false);
  const [layerRuleStypeElement, setLayerRuleStypeElement] = useState<LayerRuleStypeElement | null>(null);
  const [currentStyleIndex, setStyleIndex] = useState<number>(0);
  const [layerRules, setLayerRules] = useState<LayerRuleStypeElement[]>((layerRulesStyles?.layerRules || []));
  const [otherRules, setOtherRules] = useState<LayerRuleStypeElement[]>([]);
  const [confirmIsOpen, setConfirmIsOpen] = useState<boolean>(false)


  const toggleVisRulesOpened = (index: number) => {


    setLayerRuleStypeElement(layerRules[index]);
    const a = layerRules.filter((_, i) => i !== index);
    setOtherRules(layerRules.filter((_, i) => i !== index));
    setVisRulesOpened(true);
    setStyleIndex(index);
  };

  const closeVisRules = () => {
    setLayerRuleStypeElement(null);
    setOtherRules([]);
    setVisRulesOpened(false);
    setStyleIndex(0);
  };

  const closeAutoCategoriseRules = () => {
    setAutoCategoriseRulesOpened(false);
  };

  const onSubmit = () => {
    setLayerVisualisationRules(itemKey, layerType, defaultStyle, (layerRules || [])
      .filter(x => x.name !== '' && (x.value !== '' || x.dataType === "Integer"))
      .map(x => ({
        name: x.name,
        uniqueStyle: x.style,
        value: x.value,
        dataType: x.dataType,
        firstComparison: x.firstComparison,
        secondComparison: x.secondComparison,
        ruleChaining: x.ruleChaining,
        firstValue: x.firstValue,
        secondValue: x.secondValue,
      })));
    setLayerRuleStypeElement(null);
    setVisRulesOpened(false);
    setStyleIndex(0);
  }

  const onAddRule = () => {
    setLayerRules(prev => ([...prev, {
      name: '', value: '', style: { ...defaultStyle },
      dataType: '', firstComparison: null, firstValue: null, ruleChaining: null,
      secondComparison: null, secondValue: null
    }]));
  }

  const onAutoCategorise = () => {

    setAutoCategoriseRulesOpened(true);
  };

  const onDeleteRule = (index: number) => {
    setLayerRules(lr => lr.filter((s, i) => (i !== index)));
  };

  const move = (from: number, to: number) => {

    setLayerRules(lr => {
      const arr = [...lr];
      arr.splice(to, 0, arr.splice(from, 1)[0]);
      return arr;
    });
  };

  const setLayerVisualisationRule = (layerAttributeStyles: LayerRuleStypeElement) => {

    setLayerRules(lr => {
      lr[currentStyleIndex] = layerAttributeStyles;
      return lr;
    });
    setLayerRuleStypeElement(null);
    setVisRulesOpened(false);
    setStyleIndex(0);
  }

  const getRuleName = (la: LayerRuleStypeElement): string => {
    if (la?.dataType === "Integer") {
      let ruleName = `${la.firstComparison?.value} ${la.firstValue}`;
      if (la?.ruleChaining?.label !== undefined && la?.secondComparison?.label && la?.secondValue) {
        ruleName += `${la.ruleChaining.value === "Or" ? " Or" : " And"} ${la.secondComparison?.value} ${la.secondValue}`;
      }
      return ruleName
    }
    return la.value;
  }

  const [windowSize, setWindowSize] = useState({
    innerWidth: 0,
    innerHeight: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const customStyles = {
    content: {
      left: `${(windowSize.innerWidth - 378 - 203) / 2}px`,
      top: `${(windowSize.innerHeight - 425) / 2}px`,
    }
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      bodyOpenClassName={null}
      portalClassName="react-modal-container"
      overlayClassName="react-modal-overlay"
      className="react-modal-wrapper"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      style={customStyles}
    >
      <Draggable
        enableUserSelectHack={false}
        handle=".modal-title"
        bounds="body"
      >
        <div className={`react-modal symbology-modal ${isModalMinimized ? ' minimized' : ''}`}>
          <div className="modal-header">
            <div className="modal-title">Visualisation rules: {title}</div>
            <div className="modal-header-buttons">
              <div className="modal-control" onClick={toggleModalState}>
                {isModalMinimized ? <TbArrowsMaximize /> : <TbArrowsMinimize />}
              </div>
              <div className="modal-close" onClick={() => closeModal()}>
                <MdClose />
              </div>
            </div>
          </div>
            <div className="modal-content" style={{ maxHeight: '500px', overflow: 'auto' }}>
              {(visRulesOpened || autoCategoriseRulesOpened) && <Loader />}
              {layerRules.map((x, index) => (
                <fieldset className="xa-visualisation-rules-symbology" key={`${x?.name}-${x?.value}-${x?.label}`}>
                  <legend>{x.name}: {getRuleName(x)}</legend>
                  <div className="xa-symbology-stepper">
                    <button className={`xa-symbology-up${index === 0 ? ' xa-symbology-disabled' : ''}`} onClick={() => move(index, index - 1)}>
                      <MdKeyboardArrowUp />
                    </button>
                    <button className={`xa-symbology-down${index === layerRules.length - 1 ? ' xa-symbology-disabled' : ''}`} onClick={() => move(index, index + 1)}>
                      <MdKeyboardArrowDown />
                    </button>
                  </div>
                  <div className="xa-symbol">
                    {(layerType === 'simple-marker' || layerType === 'both') && renderMarkerType(defaultStyle?.currentStyle || '', defaultStyle?.currentColor || { r: 48, g: 54, b: 150, a: 1 })}
                    {(layerType === 'simple-line' || layerType === 'both') && renderType(defaultStyle?.currentStyle || '', defaultStyle?.currentColor || { r: 48, g: 54, b: 150, a: 1 })}
                  </div>
                  <CgArrowLongRight />
                  <div className="xa-symbol">
                    {(layerType === 'simple-marker' || layerType === 'both') && renderMarkerType(x.style?.currentMarkerStyle ? `symbols-sls-${x.style?.currentMarkerStyle}` : `symbols-sls-${x.style?.style}`, x.style?.currentColor || { r: 48, g: 54, b: 150, a: 1 })}
                    {(layerType === 'simple-line' || layerType === 'both') && renderType(x.style?.currentStyle ? x.style?.currentStyle : `symbols-sls-${x.style?.style}`, x.style?.currentColor || { r: 48, g: 54, b: 150, a: 1 })}
                  </div>
                  <button className="xa-symbology-edit" onClick={() => toggleVisRulesOpened(index)}>
                    <MdEdit />
                  </button>
                  <button className="xa-symbology-delete" onClick={() => onDeleteRule(index)}>
                    <FaTimes />
                  </button>
                </fieldset>
              ))}
               {
                confirmIsOpen ? 
                <div className="delete-confirmation-modal">
                  <p>
                    Are you sure you want to delete all rules?<br />This action is irreversible!
                  </p>
                  <div className="modal-footer">
                    <Button onClick={() => { setLayerRules([]); setConfirmIsOpen(false) }} color="danger" full isButton>
                      Delete
                    </Button>
                    <Button onClick={() => setConfirmIsOpen(false)} color="secondary" isButton>
                      Cancel
                    </Button>
                  </div>
                </div> : null
               }
            </div>
            
          {visRulesOpened && (
            <VisualisationRule
              isOpen={visRulesOpened}
              closeModal={closeVisRules}
              itemKey={itemKey}
              layerType={layerType}
              category={category}
              title={title}
              defaultStyle={defaultStyle}
              layerRuleStypeElement={layerRuleStypeElement}
              setLayerVisualisationRule={setLayerVisualisationRule}
              otherRules={otherRules}
              parameterId={parameterId}
            />
          )}

          {autoCategoriseRulesOpened && (
            <AutoCategoriseRules
              isOpen={autoCategoriseRulesOpened}
              closeModal={closeAutoCategoriseRules}
              itemKey={itemKey}
              layerType={layerType}
              category={category}
              defaultStyle={defaultStyle}
              layerRules={layerRules}
              setLayerRules={setLayerRules}
              parameterId={parameterId}
            />
          )}

          <div className="modal-footer">
            {/* <ConfirmModal isOpen={confirmIsOpen} setIsOpen={setConfirmIsOpen} setLayerRules={setLayerRules}  /> */}

            {
              layerRules.length ? <Button onClick={() => setConfirmIsOpen(true)} color="danger" full isButton>Delete All</Button> : null
            }
            <Button onClick={onAutoCategorise} color="secondary" full isButton>
              Auto-Categorise
            </Button>
            <Button onClick={onAddRule} color="primary" full isButton>
              Add Rule
            </Button>
            <Button onClick={onSubmit} color="action" isButton>
              Apply
            </Button>
          </div>
        </div>
      </Draggable>
    </Modal >
  );
}

const VisualisationRule: React.FC<VisualisationRuleProps> = ({
  isOpen = false,
  closeModal = () => { },
  itemKey,
  layerType = 'both',
  category,
  title,
  defaultStyle,
  layerRuleStypeElement,
  setLayerVisualisationRule,
  otherRules,
  parameterId
}) => {



  const [isModalMinimized, setModalMinimized] = useState<boolean>(false);
  const toggleModalState = () => setModalMinimized(prevState => !prevState);
  const [assetTypeAttributesInfo, setAssetTypeAttributesInfo] = useState<AssetTypeAttributesInfo[]>([]);
  const [attributes, setAttributes] = useState<{
    label: string,
    value: string,
  }[]>([]);
  const [attribute, setAttribute] = useState<SingleValue<{
    label: string,
    value: string,
  }> | null>();
  const [lookupValues, setLookupValues] = useState<{
    label: string,
    value: string,
  }[]>();
  const [lookupValue, setLookupValue] = useState<SingleValue<{
    label: string,
    value: string,
  }> | null>();
  //Dev area only
  const [usedValues, setUsedValues] = useState<LayerAttributeStyle[]>([]);
  //End Dev area

  const [symbologyOpened, setSymbologyOpened] = useState(false);
  const [ruleStyle, setRuleStyle] = useState<SymbologyModel>(defaultStyle);

  const [isNumericRule, setIsNumericRule] = useState<boolean>(false);

  const [firstComparison, setFirstComparison] = useState<SingleValue<{
    label: string,
    value: string,
  }> | null>(comparisonOptions[0]);

  const [secondComparison, setSecondComparison] = useState<SingleValue<{
    label: string,
    value: string,
  }> | null>(comparisonOptions[0]);

  const [ruleChaining, setRuleChaining] = useState<SingleValue<{
    label: string,
    value: string,
  }> | null>(ruleChainingOptions[0]);

  const [firstValue, setFirstValue] = useState<number | null>(0);
  const [secondValue, setSecondValue] = useState<number | null>(0);

  const [displaySecondRule, setDisplaySecondRule] = useState(false);
  const [numericRuleIsValid, setNumericRuleIsValid] = useState(false);
  const [numericError, setNumericError] = useState("");

  const [minValue, setMinValue] = useState<number>(0);
  const [maxValue, setMaxValue] = useState<number>(10000);

  useEffect(() => {
    const error = validateIntervals(otherRules.filter(x => x.name === attribute?.label),
      firstComparison?.value, firstValue, secondComparison?.value, secondValue, ruleChaining?.value, minValue, maxValue);
    const isValid = validateNumericRule(firstComparison?.value, firstValue, secondComparison?.value, secondValue, ruleChaining?.value, minValue, maxValue, displaySecondRule);
    if (error.length > 0 || !isValid) {
      setNumericRuleIsValid(false);
    }
    if (error.length === 0 && isValid) {
      setNumericRuleIsValid(true);
    }
    setNumericError(error);
  }, [firstComparison, secondComparison, ruleChaining, firstValue, secondValue, displaySecondRule, isNumericRule]);

  useEffect(() => {
    const atai = assetTypeAttributesInfo.filter(x => x.name === attribute?.label);
    if (atai.length > 0) {
      setIsNumericRule(atai[0].dataType === "Integer");

      if (atai[0].dataType === "Integer") {

        if (atai[0].hasOwnProperty('year')) {
          assetActions().getAssetTypeRange(itemKey.split('+')[0], Number(itemKey.split('+')[2]), parameterId).then((result) => {
            setMinValue(result.minValue);
            setMaxValue(result.maxValue);
          });
        } else {
          assetActions().getAssetTypeRange(itemKey.split('+')[0], atai[0].id, parameterId).then((result) => {
            setMinValue(result.minValue);
            setMaxValue(result.maxValue);
          });
        }
      }
    }
  }, [attribute?.label, itemKey]);

  const toggleSymbologyOpened = () => setSymbologyOpened(prevState => !prevState);

  useEffect(() => {
    assetActions().getAssetTypeAttributes(itemKey.split('+')[0], itemKey.split('+')[1], itemKey, parameterId).then((result) => {
      let objResult = [];
      if (!Array.isArray(result)) {
        objResult = [result];
      } else {
        objResult = result;
      }
      const res = objResult.filter(x => x.hasLookups || x.dataType === "Integer");
      if (res.length > 0) {
        // setAttribute({ value: res[0].attribute, label: res[0].attribute });
        //setLookupValues(res[0].lookupValues.map(x => ({ value: x, label: x })));
        setAttributes(res.map(x => ({ value: x.name, label: x.name })));
      }
      setAssetTypeAttributesInfo(res);
    });
  }, [itemKey]);

  useEffect(() => {
    const atai = assetTypeAttributesInfo.filter(x => x.name === attribute?.label);
    if (atai.length > 0 && isNumericRule) {

      if (atai[0].hasOwnProperty('year')) {
        assetActions().getAssetTypeRange(itemKey.split('+')[0], Number(itemKey.split('+')[2]), parameterId).then((result) => {
          setMinValue(result.minValue);
          setMaxValue(result.maxValue);
        });
      } else {
        assetActions().getAssetTypeRange(itemKey.split('+')[0], atai[0].id, parameterId).then((result) => {
          setMinValue(result.minValue);
          setMaxValue(result.maxValue);
        });
      }
    }
  }, [itemKey, attributes, attribute?.label, isNumericRule]);

  useEffect(() => {
    if (layerRuleStypeElement?.name) {
      setAttribute({
        label: layerRuleStypeElement?.name,
        value: layerRuleStypeElement?.name
      })
    }
    if (layerRuleStypeElement?.value) {
      setLookupValue({
        label: layerRuleStypeElement?.value,
        value: layerRuleStypeElement?.value
      })
    }
    if (layerRuleStypeElement?.dataType && layerRuleStypeElement?.dataType === "Integer") {
      setIsNumericRule(true);
    }
    if (layerRuleStypeElement?.firstComparison) {
      setFirstComparison(layerRuleStypeElement?.firstComparison);
    }
    if (layerRuleStypeElement?.secondComparison) {
      setSecondComparison(layerRuleStypeElement?.secondComparison);
    }
    if (layerRuleStypeElement?.firstValue) {
      setFirstValue(layerRuleStypeElement?.firstValue);
    }
    if (layerRuleStypeElement?.secondValue) {
      setSecondValue(layerRuleStypeElement?.secondValue);
    }
    if (layerRuleStypeElement?.ruleChaining) {
      setRuleChaining(layerRuleStypeElement?.ruleChaining);
    }

    if (layerRuleStypeElement?.secondComparison && layerRuleStypeElement?.secondValue && layerRuleStypeElement?.ruleChaining) {
      setDisplaySecondRule(true);
    }
  }, [layerRuleStypeElement?.name, layerRuleStypeElement?.value, layerRuleStypeElement?.dataType,
  layerRuleStypeElement?.firstComparison, layerRuleStypeElement?.firstValue,
  layerRuleStypeElement?.secondComparison, layerRuleStypeElement?.secondValue,
  layerRuleStypeElement?.ruleChaining
  ])

  useEffect(() => {
    if (layerRuleStypeElement) {
      setRuleStyle(layerRuleStypeElement?.style);
    } else if (defaultStyle) {
      setRuleStyle(defaultStyle);
    }
  }, [defaultStyle, layerRuleStypeElement]);

  const setLayerStyle = (key: string, color: RgbaColor, width: number, size: number, lineStyle: string, markerStyle: string, angle: number) => {
    setRuleStyle(prev => ({
      ...prev,
      currentWidth: width,
      width: `${width}px`,
      currentSize: size,
      size: `${size}px`,
      currentLineStyle: lineStyle,
      currentMarkerStyle: markerStyle,
      currentStyle: layerType === 'simple-marker' ? `symbols-sls-${markerStyle}` : `symbols-sls-${lineStyle}`,
      currentAngle: angle,
      currentColor: color,
    }));
    toggleSymbologyOpened();
  };

  const onSubmit = () => {
    if (isNumericRule) {
      const isValid = validateNumericRule(firstComparison?.value, firstValue, secondComparison?.value, secondValue, ruleChaining?.value, minValue, maxValue, displaySecondRule);
      setNumericRuleIsValid(isValid);
      if (!isValid) {
        return;
      }
    }
    if (isNumericRule) {
      const error = validateIntervals(otherRules.filter(x => x.name === attribute?.label), firstComparison?.value, firstValue, secondComparison?.value, secondValue, ruleChaining?.value, minValue, maxValue);
      if (error.length > 0) {
        setNumericRuleIsValid(false);
      }
      setNumericError(error);
    }
    const atai = assetTypeAttributesInfo.filter(x => x.name === attribute?.label);
    setUsedValues([...usedValues, {
      name: attribute?.value || '', value: lookupValue?.value || '', uniqueStyle: ruleStyle,
      dataType: atai[0].dataType,
      firstComparison: isNumericRule ? firstComparison : null,
      secondComparison: isNumericRule && displaySecondRule ? secondComparison : null,
      ruleChaining: isNumericRule && displaySecondRule ? ruleChaining : null,
      firstValue: isNumericRule ? firstValue : null,
      secondValue: isNumericRule && displaySecondRule ? secondValue : null
    }]);

    setLayerVisualisationRule({
      name: attribute?.value || '', value: lookupValue?.value || '', style: ruleStyle,
      dataType: atai[0].dataType,
      firstComparison: isNumericRule ? firstComparison : null,
      secondComparison: isNumericRule && displaySecondRule ? secondComparison : null,
      ruleChaining: isNumericRule && displaySecondRule ? ruleChaining : null,
      firstValue: isNumericRule ? firstValue : null,
      secondValue: isNumericRule && displaySecondRule ? secondValue : null
    });
  }

  const onAttributeChange = (newVal: SingleValue<{
    label: string,
    value: string,
  }>) => {
    setAttribute(newVal);
    setLookupValue(null);
    const atai = assetTypeAttributesInfo.filter(x => x.name === newVal?.label);
    if (atai.length > 0) {
      setLookupValues(atai[0].lookupValues.map(x => ({ value: x, label: x })));
    }
  }

  const onLookupValueChange = (newVal: SingleValue<{
    label: string,
    value: string,
  }>) => {
    setLookupValue(newVal);
  }

  const [windowSize, setWindowSize] = useState({
    innerWidth: 0,
    innerHeight: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const customStyles = {
    content: {
      left: `${(windowSize.innerWidth - 378 - 203) / 2}px`,
      top: `${(windowSize.innerHeight - 425) / 2}px`,
    }
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      bodyOpenClassName={null}
      portalClassName="react-modal-container"
      overlayClassName="react-modal-overlay"
      className="react-modal-wrapper"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      style={customStyles}
    >
      <Draggable
        enableUserSelectHack={false}
        handle=".modal-title-overlay"
        bounds="body"
      >
        <div className={`react-modal symbology-modal ${isModalMinimized ? ' minimized' : ''}`}>
          <div className="modal-header">
            <div className="modal-title-overlay">Visualisation rules: {title}</div>
            <div className="modal-header-buttons">
              <div className="modal-control" onClick={toggleModalState}>
                {isModalMinimized ? <TbArrowsMaximize /> : <TbArrowsMinimize />}
              </div>
              <div className="modal-close" onClick={() => closeModal()}>
                <MdClose />
              </div>
            </div>
          </div>
          <div className="modal-content">
            <div className="xa-form-field">
              <strong>Attribute</strong>
              <Select
                options={attributes}
                onChange={onAttributeChange}
                value={attribute}
                className={'custom_select'}
                menuPlacement="bottom"
                classNamePrefix="custom_select"
              />
            </div>
            {isNumericRule &&
              (
                <NumericVisualisationRules
                  firstComparison={firstComparison}
                  setFirstComparison={setFirstComparison}
                  secondComparison={secondComparison}
                  setSecondComparison={setSecondComparison}
                  ruleChaining={ruleChaining}
                  setRuleChaining={setRuleChaining}
                  firstValue={firstValue}
                  setFirstValue={setFirstValue}
                  secondValue={secondValue}
                  setSecondValue={setSecondValue}
                  displaySecondRule={displaySecondRule}
                  setDisplaySecondRule={setDisplaySecondRule}
                  minValue={minValue}
                  maxValue={maxValue}
                  isInvalid={!numericRuleIsValid}
                  error={numericError}
                />
              )}
            {!isNumericRule &&
              (
                <div className="xa-form-field">
                  <strong>Value</strong>
                  <Select
                    options={lookupValues}
                    onChange={onLookupValueChange}
                    isDisabled={!attribute}
                    value={lookupValue}
                    className={'custom_select'}
                    menuPlacement="bottom"
                    classNamePrefix="custom_select"
                  />
                </div>
              )}
            {(layerType === 'simple-marker' || layerType === 'both') &&
              (
                <fieldset className="xa-visualisation-rules-symbology">
                  <legend>Symbology</legend>
                  <div></div>
                  <div>
                    {renderMarkerType(defaultStyle?.currentStyle || '', defaultStyle?.currentColor || { r: 48, g: 54, b: 150, a: 1 })}
                  </div>
                  <CgArrowLongRight />
                  <div>
                    {renderMarkerType(ruleStyle?.currentStyle || '', ruleStyle?.currentColor || { r: 48, g: 54, b: 150, a: 1 })}
                  </div>
                  <button className="xa-symbology-edit" onClick={toggleSymbologyOpened}>
                    <MdEdit />
                  </button>
                </fieldset>
              )}
            {(layerType === 'simple-line' || layerType === 'both') &&
              (
                <fieldset className="xa-visualisation-rules-symbology">
                  <legend>Symbology</legend>
                  <div></div>
                  <div>
                    {renderType(defaultStyle?.currentStyle || '', defaultStyle?.currentColor || { r: 48, g: 54, b: 150, a: 1 })}
                  </div>
                  <CgArrowLongRight />
                  <div>
                    {renderType(ruleStyle?.currentStyle || '', ruleStyle?.currentColor || { r: 48, g: 54, b: 150, a: 1 })}
                  </div>
                  <button className="xa-symbology-edit" onClick={toggleSymbologyOpened}>
                    <MdEdit />
                  </button>
                </fieldset>
              )}
          </div>

          {symbologyOpened && (
            <Symbology
              isOpen={symbologyOpened}
              closeModal={toggleSymbologyOpened}
              itemKey={itemKey}
              category={category}
              title={title}
              setLayerStyle={setLayerStyle}
              currentWidth={ruleStyle?.currentWidth}
              currentSize={ruleStyle?.currentSize}
              currentLineStyle={ruleStyle?.currentLineStyle}
              currentMarkerStyle={ruleStyle?.currentMarkerStyle}
              currentColor={ruleStyle?.currentColor}
              currentAngle={ruleStyle?.currentAngle}
              layerType={layerType}
            />
          )}


          <div className="modal-footer">
            <Button onClick={onSubmit} color="action" full isButton disabled={isNumericRule ? !numericRuleIsValid : !attribute || !lookupValue}>
              Apply
            </Button>
          </div>
        </div>
      </Draggable>
    </Modal >
  );
}

export default VisualisationRules;
