import Tenants from 'components/AdministrationTools//Tenants/Tenants';
import NavBar from 'components/NavBar';
import UserContainer from 'components/UserContainer';
import { useAppSelector } from 'hooks/appSelector';
import React, { useState } from 'react'
import './style.scss'
import NewUsers from 'components/AdministrationTools/NewUsers/NewUsers';
import RegisteredUsers from 'components/AdministrationTools/RegisteredUsers';
import withAdminCheck from 'HOC/withAdminCheck';

type AdministrationToolsProps = {};

const AdministrationTools: React.FC<AdministrationToolsProps> = () => {

  const user = useAppSelector((state) => state.user);
  const [primaryIsExpanded, setPrimaryIsExpanded] = React.useState<boolean | null>(null);
  const [modalState, setModalState] = useState<Record<string, boolean>>();

  const showModal = (title: string) => setModalState((prevState) => ({ ...prevState, [title]: true }));

  return (
    <div className="xa-admin-page">
      <NavBar
        displayModal={showModal}
        isExpanded={primaryIsExpanded}
        setIsExpanded={setPrimaryIsExpanded}
      />
      <div className='xa-admin-content'>
        <Tenants />
        <NewUsers />
        <RegisteredUsers />
      </div>
      <UserContainer
        email={user.email}
        firstname={user.firstname}
        surname={user.surname}
        navbarExpanded={primaryIsExpanded}
      />
    </div>
  )
}

export default withAdminCheck(AdministrationTools);
