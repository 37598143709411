import React, { useEffect, useInsertionEffect } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import Login from 'containers/Login/index';
import Register from 'containers/Register/index';
import AuthLayout from 'layouts/AuthLayout/AuthLayout';
import MainLayout from 'layouts/MainLayout/index';
import Dashboard from 'containers/Dashboard/index';
import { useUserActions } from 'actions/user';
import { instance } from 'utils/request';
import './App.scss';
import AdministrationTools from 'containers/AdministrationTools';
const getRoutes = (isLogedIn: boolean, pathname: string) => ([{
  path: '/',
  element: isLogedIn ? <MainLayout /> : <Navigate to='/login' state={{ from: pathname }} />,
  children: [
    { path: '/', element: <Dashboard /> },
    { path: '/administration-tools', element: <AdministrationTools /> },
    { path: '*', element: <Navigate to='/' /> },
  ],
}, {
  path: '/login',
  element: <AuthLayout />,
  children: [
    { path: '/login', element: <Login /> },
    { path: '*', element: <Navigate to='/login' /> },
  ],
}, {
  path: '/register',
  element: <AuthLayout />,
  children: [
    { path: '/register', element: <Register /> },
    { path: '*', element: <Navigate to='/register' /> },
  ],
}
]);

const App: React.FC = (): JSX.Element => {
  let location = useLocation();
  const userActions = useUserActions();
  const routing = useRoutes(getRoutes(userActions.restoreUser(), location.pathname));

  useInsertionEffect(() => {
    userActions.getUserTheme();
  }, []);

  const documentHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--vh', `${window.innerHeight}px`);
  }
  window.addEventListener('resize', documentHeight);
  documentHeight();

  instance.interceptors.response.use(response => response,
    (error) => {
      if (error?.response?.status === 401) {
        userActions.logout();
      }
      return Promise.reject(error);
    });

  return <>{routing}</>;
}

export default App;
