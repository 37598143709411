import { BsPatchCheckFill, BsPatchMinusFill, BsPatchQuestionFill } from "react-icons/bs";
import humanizeKey from 'humanize-key';

const renderOptionalValue = (value: string | undefined) => {
  if (value === 'checked') {
    return (
      <BsPatchCheckFill className="xa-attributes-list-value-true" />
    )
  }
  return (
    value === "?" ? (<BsPatchQuestionFill className="xa-attributes-list-value-unknown" />) : (<>{value}</>)
  );
};


const renderValue = (name: string, value: string | undefined) => {
  switch (name) {
    case ('adopted'):
      {
        return (
          value
            ?
            (<BsPatchCheckFill className="xa-attributes-list-value-true" />)
            :
            (<BsPatchMinusFill className="xa-attributes-list-value-unknown" />)
        );
      }
    default:
      return renderOptionalValue(value);
  }
};

interface Props {
  name: string,
  value: string,
}

const AttributeRow: React.FC<Props> = ({ name, value }) => {
  return (
    <div className="xa-attributes-list-row">
      <div className="xa-attributes-list-label">{humanizeKey(name)}</div>
      <div className="xa-attributes-list-value">{renderValue(name, value)}</div>
    </div>)
}

export default AttributeRow;
