import { UserInfo } from 'actions/administration-tools'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


const initialState: {user: UserInfo | null} = {
    user: null
}

export const counterSlice = createSlice({
  name: 'administrationTools',
  initialState,
  reducers: {
    moveNewUserToRegistered: (state, action: PayloadAction<UserInfo>) =>{
        state.user = action.payload
    },
    updateUserFromRegisteredToNew: (state, action: PayloadAction<UserInfo>) =>{
      state.user = action.payload
  },
    clearUsers: (state) => {
        state.user = null
    }
  },
})

export const { moveNewUserToRegistered, clearUsers, updateUserFromRegisteredToNew} = counterSlice.actions

export default counterSlice.reducer
