import Modal from 'react-modal';
import React, { useState, useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import { TbArrowsMinimize, TbArrowsMaximize } from 'react-icons/tb';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { networkActions, SectionInfo } from 'actions/network';
import { assetActions, AssetAttributesInfo, AssetElementsInfo, AssetInfo, EyeViDto } from 'actions/asset';
import AttributeRow from './attributeRow';
import ElementRow from './elementRow';
import { accidentActions, AccidentInfo } from 'actions/accident';
import { surveyActions, SurveyInfo } from 'actions/survey';
import { FwpInfo, schemeActions, SchemeInfo, TreatmentInfo } from 'actions/scheme';
import { performanceActions, PerformanceInfo } from 'actions/performance';
import Draggable from 'react-draggable';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Iframe from 'react-iframe';
import 'react-tabs/style/react-tabs.css';
import './style.scss';
import AssetHub from './assetHub';
import { FiRefreshCw } from 'react-icons/fi';

interface Props {
  isOpen: boolean,
  data: any,
  closeModal: (title: string) => void,
}

const Attributes: React.FC<Props> = ({
  isOpen = false,
  data,
  closeModal,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isModalMinimized, setModalMinimized] = useState<boolean>(false);
  const [isEVModalMinimized, setEVModalMinimized] = useState<boolean>(false);
  const [modalDetails, setModalDetails] = useState<any>();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const changeTab = (index: number) => setCurrentTab(index);
  const [showAttributes, setShowAttributes] = useState<boolean>(false);
  const [attributes, setAttributes] = useState<AssetAttributesInfo[]>([]);
  const [elements, setElements] = useState<AssetElementsInfo[]>([]);
  const [showElements, setShowElements] = useState<boolean>(false);
  const [spans, setSpans] = useState<number[]>([]);
  const [selectedSpan, setSelectedSpan] = useState<number>(1);
  const [selectedYear, setSelectedYear] = useState<number>(0);
  const [selectedAssetType, setSelectedAssetType] = useState<string>('');
  const [selectedEyeviYear, setSelectedEyeviYear] = useState<string>('uk2021');
  const [eyeViYears, setEyeViYears] = useState<number[]>([]);
  const [eyeViAssetTypes, setEyeViAssetTypes] = useState<string[]>([]);
  const [showEyeVi, setShowEyeVi] = useState<boolean>(false);
  const [eyeViData, setEyeViData] = useState<EyeViDto[]>([]);
  const [showAssetHub, setShowAssetHub] = useState<boolean>(false);
  const [attributesRefreshLoading, setAttributesRefreshLoading] = useState(false);
  const [wasAttributesRefreshedClicked, setWasAttributesRefreshedClicked] = useState(false);
  const [wasAssetHubRefreshedClicked, setWasAssetHubRefreshedClicked] = React.useState<boolean>(false);
  const [wasAssetHubRefreshLoading, setWasAssetHubRefreshLoading] = React.useState<boolean>(false);

  useEffect(() => {
    let formatedYear = selectedAssetType === 'FW' ? `uk${selectedYear}footway` : `uk${selectedYear}`;

    const lineCoordinates = (modalDetails
      ?.geometry || '')
      .replace('LINESTRING', '')
      .replace('(', '')
      .replace(')', '')
      .trim()
      .split(',');
    let coordinates = [];
    if (data?.latitude && data?.longitude) {
      formatedYear = `${formatedYear}&lon=${data?.longitude}&lat=${data?.latitude}`
    }
    else if (lineCoordinates.length > 0) {
      coordinates = lineCoordinates[0].split(' ');
      formatedYear = `${formatedYear}&lon=${coordinates[0]}&lat=${coordinates[1]}`
    }


    setSelectedEyeviYear(formatedYear);
  }, [selectedYear, selectedAssetType, modalDetails?.geometry, data?.latitude, data?.longitude])

  useEffect(() => {
    setCurrentTab(0);
    setShowAttributes(false);
    setShowElements(false);
    setModalDetails({});
    setShowAssetHub(false)

  }, [data]);

  useEffect(() => {
    if (showAttributes && data?.category === 'asset' && modalDetails.id && currentTab === 1) {
      assetActions().getAssetAttributes(modalDetails.id, wasAssetHubRefreshedClicked).then((result) => {
        setAttributes(result);
      });
    }
  }, [showAttributes, currentTab]);

  useEffect(() => {
    if (showElements && data?.category === 'asset' && modalDetails.id &&
      ((currentTab === 1 && !showAttributes) || currentTab === 2)) {
      assetActions().getAssetElements(modalDetails.id, wasAssetHubRefreshedClicked).then((result) => {
        setElements(result);
      });
    }
  }, [showElements, currentTab, wasAssetHubRefreshLoading]);


  useEffect(() => {
    if (showElements && data?.category === 'asset' && modalDetails.id) {
      assetActions().getSpans(modalDetails.id, wasAssetHubRefreshedClicked).then((result) => {
        setSpans([...new Array(result)].map((x, y) => y + 1));
      });
    }
  }, [showElements, wasAssetHubRefreshLoading]);

  const parseModalDetails = (modalDet: any) => {
    setModalDetails(modalDet);
    setShowAttributes(modalDet.attributes || false);
    setShowElements(modalDet.elements || false);
    setIsLoading(false);
  };

  const parseEyeViYears = (years: EyeViDto[]) => {
    if ((years || []).length > 0) {
      setEyeViData(years);
      const assetType = years[0].assetType;
      setSelectedAssetType(assetType);
      const eyeViAssetYears = years.filter(x => x.assetType === assetType).map(x => x.year);
      const eyeViAssetTypes = Array.from(new Set(years.map(x => x.assetType)));
      setEyeViYears(eyeViAssetYears);
      setEyeViAssetTypes(eyeViAssetTypes);
      if ((eyeViAssetYears || []).length > 0) {
        setSelectedYear(eyeViAssetYears[0]);
      }
    }

  }

  const toggleModalState = () => setModalMinimized(prevState => !prevState);

  const toggleEVModalState = () => setEVModalMinimized(prevState => !prevState);

  const executeActionPerDataCategoryType = (data: { category?: any; id: any; name?: any; Id?: any; }, parseEyeViYears: {
    (years: EyeViDto[]): void;
    (arg0: EyeViDto[]): void;
  }, parseModalDetails: {
    (modalDet: any): void;
    (arg0: AssetInfo | AccidentInfo | SurveyInfo | SchemeInfo | FwpInfo | TreatmentInfo | PerformanceInfo | SectionInfo): void;
  }, refreshData: boolean = false) => {
    if (data?.category === 'network') {
      assetActions().getEyeViYears(refreshData).then((result) => {
        parseEyeViYears(result);
        setAttributesRefreshLoading(false);
      });
    }

    switch (data?.category) {
      case ('asset'): {
        assetActions().getAssetDetails(data.id, refreshData).then((result) => {
          parseModalDetails(result);
          setShowAssetHub(true);
          if (refreshData) {
            setAttributesRefreshLoading(false);
            setWasAttributesRefreshedClicked(false);
          }
        });
        break;
      }
      case ('accident'): {
        accidentActions().getAccidentDetails(data.name, refreshData).then((result) => {
          parseModalDetails(result);
          if (refreshData) {
            setAttributesRefreshLoading(false);
            setWasAttributesRefreshedClicked(false);
          }
        });
        break;
      }
      case ('survey'): {
        surveyActions().getSurveyDetails(data.id, refreshData).then((result) => {
          parseModalDetails(result);
          if (refreshData) {
            setAttributesRefreshLoading(false);
            setWasAttributesRefreshedClicked(false);
          }
        });
        break;
      }
      case ('inspection'): {
        surveyActions().getSurveyInspectionDetails(data.id ?? data.Id, refreshData).then((result) => {
          parseModalDetails(result);
          if (refreshData) {
            setAttributesRefreshLoading(false);
            setWasAttributesRefreshedClicked(false);
          }
        });
        break;
      }
      case ('scheme'): {
        schemeActions().getSchemeDetails(data, refreshData).then((result) => {
          parseModalDetails(result);
          if (refreshData) {
            setAttributesRefreshLoading(false);
            setWasAttributesRefreshedClicked(false);
          }
        });
        break;
      }
      case ('candidate schemes'): {
        schemeActions().getFwpDetails(data.id, refreshData).then((result) => {
          parseModalDetails(result);
          setAttributesRefreshLoading(false);
        });
        break;
      }
      case ('programmed scheme'): {
        schemeActions().getProgrammedSchemeDetails(data, refreshData).then((result) => {
          parseModalDetails(result);
          setAttributesRefreshLoading(false);
        });
        break;
      }
      case ('treatment'): {
        schemeActions().getTreatmentDetails(data.id, refreshData).then((result) => {
          parseModalDetails(result);
          if (refreshData) {
            setAttributesRefreshLoading(false);
            setWasAttributesRefreshedClicked(false);
          }
        });
        break;
      }
      case ('performance'): {
        performanceActions().getPerformanceDetails(data.id, refreshData).then((result) => {
          parseModalDetails(result);
          if (refreshData) {
            setAttributesRefreshLoading(false);
            setWasAttributesRefreshedClicked(false);
          }
        });
        break;
      }
      default:
        networkActions().getSectionDetails(data.name, refreshData).then((result) => {
          parseModalDetails(result);
          if (refreshData) {
            setAttributesRefreshLoading(false);
            setWasAttributesRefreshedClicked(false);
          }
        });
        break;
    }
  }

  useEffect(() => {
    if (isOpen) {
      executeActionPerDataCategoryType(data, parseEyeViYears, parseModalDetails);
    }
  }, [isOpen, data]);

  const onChangeSpan = (sp: string) => {
    setSelectedSpan(parseInt(sp));
  };

  const onChangeYear = (sp: string) => {
    setSelectedYear(parseInt(sp));
  };

  const onChangeAssetType = (sp: string) => {
    setSelectedAssetType(sp);
    const eyeViAssetYears = eyeViData.filter(x => x.assetType === sp).map(x => x.year);
    setEyeViYears(eyeViAssetYears);
    if ((eyeViAssetYears || []).length > 0) {
      setSelectedYear(eyeViAssetYears[0]);
    }
  };


  const onOpenEyeVi = () => {
    setIsLoading(true);
    assetActions().getEyeViCredentials(selectedAssetType, selectedYear).then((result) => {
      if (result || true) {
        setShowEyeVi(true);
      }
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const closeEyeViModal = () => {
    setShowEyeVi(false);
    setEVModalMinimized(false);
  }

  return (
    <>
    {
      showAssetHub ?
        <AssetHub
          closeModal={closeModal}
          data={data}
          wasAssetHubRefreshedClicked={wasAssetHubRefreshedClicked}
          setWasAssetHubRefreshedClicked={setWasAssetHubRefreshedClicked}
          wasAssetHubRefreshLoading={wasAssetHubRefreshLoading}
          setWasAssetHubRefreshLoading={setWasAssetHubRefreshLoading}
        /> :
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        bodyOpenClassName={null}
        portalClassName="react-modal-container"
        overlayClassName="react-modal-overlay"
        className="react-modal-wrapper"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
      >
        <Draggable
          enableUserSelectHack={false}
          handle=".modal-header"
          bounds="body"
        >
          <div className={`react-modal top-left${isModalMinimized ? ' minimized' : ''}`}>
            <div className="modal-header">
              <div className="modal-title-container">
                <div className="modal-title-in-container">Selected Item</div>
                <FiRefreshCw
                  onClick={() => {
                    setWasAttributesRefreshedClicked(true);
                    setAttributesRefreshLoading(true);
                    executeActionPerDataCategoryType(data, parseEyeViYears, parseModalDetails, true)
                  }}
                  className={attributesRefreshLoading && wasAttributesRefreshedClicked ? 'modal-refresh-button rotating' : 'modal-refresh-button'}/>
              </div>
              <div className="modal-header-buttons">
                <div className="modal-control" onClick={toggleModalState}>
                  {isModalMinimized ? <TbArrowsMaximize /> : <TbArrowsMinimize />}
                </div>
                <div className="modal-close" onClick={() => closeModal('Attributes')}>
                  <MdClose />
                </div>
              </div>
            </div>
            <div className="modal-content">
              {isLoading && <Loader />}
              <Tabs
                selectedIndex={currentTab}
                onSelect={changeTab}
              >
                <TabList>
                  <Tab>Info</Tab>
                  {showAttributes && <Tab>Attributes</Tab>}
                  {showElements && <Tab>Elements</Tab>}
                </TabList>

                <TabPanel>
                  <div className="xa-attributes-list">
                    {/* List head */}
                    <div className="xa-attributes-list-head">
                      <div className="xa-attributes-list-label">Name</div>
                      <div className="xa-attributes-list-value">Value</div>
                    </div>


                    {/* List rows */}
                    {(modalDetails !== undefined && modalDetails != null) ? Object.keys(modalDetails)
                      .filter((k) => k !== 'geometry' && k !== 'attributes'
                        && k !== 'elements' && k !== 'segmentable')
                      .map((key) => (
                        <AttributeRow
                          name={key}
                          value={modalDetails[key]}
                          key={key}
                        />
                      )) : <></>
                    }
                    {/* List rows */}

                  </div>
                </TabPanel>

                {showAttributes && (<TabPanel>
                  <div className="xa-attributes-list">
                    {/* List head */}
                    <div className="xa-attributes-list-head">
                      <div className="xa-attributes-list-label">Name</div>
                      <div className="xa-attributes-list-value">Value</div>
                    </div>


                    {/* List rows */}
                    {attributes
                      .map((att) => (
                        <AttributeRow
                          name={att.name}
                          value={att.value}
                          key={att.name}
                        />
                      ))
                    }
                    {/* List rows */}

                  </div>
                </TabPanel>)}
                { showElements && (
                    <TabPanel>
                      <div className="xa-attributes-elements">
                        <label htmlFor="groups" className="required">Span</label>
                        <select name="groups" id="groups" onChange={(e) => onChangeSpan(e.target.value)}>
                          {spans?.map((value) => (
                            <option value={value} key={value} selected={selectedSpan === value}>
                              {value}
                            </option>))}
                        </select>
                      </div>
                      <div className="xa-attributes-list">
                        {/* List head */}
                        <div className="xa-attributes-list-head">
                          <div className="xa-attributes-list-label full-width">Name</div>
                        </div>


                        {/* List rows */}
                        {elements
                          .filter(x => x.spanNumber === selectedSpan)
                          .map((el) => (
                            <ElementRow
                              name={el.name}
                              key={`${el.name}-${el.spanNumber}`}
                            />
                          ))
                        }
                        {/* List rows */}

                      </div>
                    </TabPanel>
                  )
                }
              </Tabs>

            </div>
            <div className="modal-footer">
              {((eyeViYears || []).length > 0 && data?.category === 'network') && (
                <>
                  <strong className="xa-eyevi-title">EyeVi</strong>
                  <div className="xa-eyevi-row">
                    <div>
                      <select name="eyeViYears" id="eyeViYears" onChange={(e) => onChangeYear(e.target.value)}>
                        {eyeViYears?.map((value) => (
                          <option value={value} key={value} selected={selectedYear === value}>
                            {value}
                          </option>))}
                      </select>
                    </div>
                    <div>
                      <select name="eyeViAssetTypes" id="eyeViAssetTypes" onChange={(e) => onChangeAssetType(e.target.value)}>
                        {eyeViAssetTypes?.map((value) => (
                          <option value={value} key={value} selected={selectedAssetType === value}>
                            {value}
                          </option>))}
                      </select>
                    </div>
                  </div>
                  <div className="xa-eyevi-btns">
                    <Button onClick={onOpenEyeVi} color="primary" full isButton>
                      Open
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </Draggable>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={showEyeVi}
        bodyOpenClassName={null}
        portalClassName="react-modal-container"
        overlayClassName="react-modal-overlay"
        className="react-modal-wrapper"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
      >
        <div className={`react-modal top-left${isEVModalMinimized ? ' minimized' : ''}`}>
          <div className="modal-header">
            <div className="modal-title">EyeVi</div>
            <div className="modal-header-buttons">
              <div className="modal-control" onClick={toggleEVModalState}>
                {isEVModalMinimized ? <TbArrowsMaximize /> : <TbArrowsMinimize />}
              </div>
              <div className="modal-close" onClick={() => closeEyeViModal()}>
                <MdClose />
              </div>
            </div>
          </div>
          <div className="modal-content">
            <Iframe url={`${process.env.REACT_APP_EYEVI_URL}/dist/#${selectedEyeviYear}`}
              width="2000"
              height="2000"
              id=""
              className=""
              display="block"
              position="relative" />
          </div>
        </div>
      </Modal>
    </div>
    }
    </>
  );
}

export default Attributes;
