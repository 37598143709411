import { useEffect, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import  { useLoadItems } from './useLoadItems';
import './style.scss';

interface Props {
    type?: string,
    selectedKey?: string,
    keyword?: string,
    category?: string,
    setSearchContent: (item: string) => void,
    setSearchFocused: (item: boolean) => void,
    onSelectSearchDetailsItem: (item: string) => void,
    setTotalItems: (total: number) => void,
}
  
function isNil(value: any): boolean {
    return value === undefined || value === null;
  }


  const SearchResultList: React.FC<Props> = ({
    type,
    selectedKey,
    keyword,
    category,
    setSearchContent,
    setSearchFocused,
    onSelectSearchDetailsItem,
    setTotalItems,
  }) => {
    const { loading, items, paginationData, hasNextPage, error, loadMore } = useLoadItems(type, selectedKey, keyword, category);


    useEffect(() => {
      setTotalItems(paginationData.totalItems);
    }, [paginationData.totalItems]);

    const [sentryRef] = useInfiniteScroll({
      loading,
      hasNextPage,
      onLoadMore: loadMore,
      // When there is an error, we stop infinite loading.
      // It can be reactivated by setting "error" state as undefined.
      disabled: !!error,
      // `rootMargin` is passed to `IntersectionObserver`.
      // We can use it to trigger 'onLoadMore' when the sentry comes near to become
      // visible, instead of becoming fully visible on the screen.
      rootMargin: '0px 0px 400px 0px',
    });
  
    return (
        <div className="xa-search-results">
        {!isNil(type) && !isNil(keyword) && paginationData.totalItems > 0 &&
            <div className="xa-search-results-title">Search Results: {paginationData.totalItems}</div>
        }
        {items.map((item,index) => (
            <div  key={index}
                  onClick={() => {
                    setSearchContent(item);
                    setSearchFocused(false);
                    onSelectSearchDetailsItem(item);
                  }}
            className="xa-search-results-item">{item}</div>
        ))}
         {(loading || hasNextPage) && (
          <div
            key='loading-item'
            ref={sentryRef}
            className="xa-search-results-item">
            Loading...
          </div>
        )}
      </div>

    );
  }

  export default SearchResultList;