import { get, patch } from 'utils/request';

export interface TenantInfo {
    "name": string,
    "users": number,
    "newUsers": number,
    "lastActive": string,
    "status": string;
    "admins": number;
    "deactiveUsers": number;
}

export interface UserInfo {
    "id": number,
    "tenant": string | null,
    "username": string | null,
    "firstname": string | null,
    "surname": string | null,
    "email": string | null,
    "registerDate": string | null,
    "lastActive": string | null,
    "approved": boolean,
    "approvedDate": boolean,
    "deactivated": boolean,
    "deactivatedDate": null,
    "admin": boolean,
    "comments": string | null
}

const administrationToolsActions = () => {
    const getTenantsInformation = async (): Promise<TenantInfo[]> => {
        const response = await get<TenantInfo[]>(`admin/tenants`);
        const responseData = response.data;
        return responseData;
    }
    const getSingleTenantInformation = async (id: string): Promise<TenantInfo> => {
        const response = await get<TenantInfo>(`admin/tenants/${id}`);
        const responseData = response.data;
        return responseData;
    }
    const getUsersInformation = async (): Promise<UserInfo[]> => {
        const response = await get<UserInfo[]>(`admin/users`);
        const responseData = response.data;
        return responseData;
    }

    const approveUser = async (data: {tenant: string, userId: number}): Promise<UserInfo> =>{
        const response = await patch<typeof data, UserInfo>('admin/users/approve', data);
        const responseData = response.data;
        return responseData;
    }

    const makeUserAdmin = async (data: {tenant: string, userId: number}): Promise<UserInfo> =>{
        const response = await patch<typeof data, UserInfo>('admin/users/admin', data);
        const responseData = response.data;
        return responseData;
    }

    const toggleActivateUser = async (data: {tenant: string, userId: number}): Promise<UserInfo> =>{
        const response = await patch<typeof data, UserInfo>('admin/users/activate', data);
        const responseData = response.data;
        return responseData;
    }

    const commentUser = async (data: {tenant: string, userId: number, comment: string}): Promise<UserInfo> =>{
        const response = await patch<typeof data, UserInfo>('admin/users/comment', data);
        const responseData = response.data;
        return responseData;
    }

    const deleteUserComments = async (data: {tenant: string, userId: number}): Promise<UserInfo> =>{
        const response = await patch<typeof data, UserInfo>('admin/users/comment/clear', data);
        const responseData = response.data;
        return responseData;
    }

    
    const declineUser = async (data: {tenant: string, userId: number, comment: string}): Promise<UserInfo> =>{
        const response = await patch<typeof data, UserInfo>('admin/users/decline', data);
        const responseData = response.data;
        return responseData;
    }

    return {
        getTenantsInformation,
        getUsersInformation,
        approveUser,
        makeUserAdmin,
        toggleActivateUser,
        commentUser,
        deleteUserComments,
        declineUser,
        getSingleTenantInformation
    };
};

export { administrationToolsActions };
