import React from 'react';
import './style.scss';

type ToggleSwitchProps = {
  label: string;
  isOn: boolean;
  onClick: () => void;
};

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ label, isOn, onClick }) => {
  return (
    <div className="xa-toggle-switch">
      <input onClick={onClick} type="checkbox" id="switch" checked={isOn} />
      <label htmlFor="switch">{label}</label>
    </div>
  );
};

export default ToggleSwitch;
