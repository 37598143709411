import { ResponsivePie } from '@nivo/pie';
import { ResponsiveBar, BarDatum } from '@nivo/bar';
import { useState, useEffect } from 'react';
import { useUserActions } from 'actions/user';
import {  StatisticsResponse } from '../../../../actions/statistics';
import { assetActions } from 'actions/asset';
import EmptyRequestDisplay from '../AssetHubNoData';
import ToggleSwitchWithText from 'components/ToggleSwitchWithText';

export interface DataChart {
  id: string;
  value: string;
  customLabel: string;
}

interface ChartHubProps {
  id: number;
  requestType: string,
  wasAssetHubRefreshedClicked: boolean,
  wasAssetHubRefreshLoading: boolean,
}

const CHART_OPTIONS = [
  { value: 'Pie', label: 'Pie' },
  { value: 'Bar', label: 'Bar' }
]


const ChartHub: React.FC<ChartHubProps> = ({
  id,
  requestType,
  wasAssetHubRefreshedClicked,
  wasAssetHubRefreshLoading
}) => {
  const [theme, setTheme] = useState('');
  const userActions = useUserActions();
  const [groupStatus, setGroupStatus] = useState<StatisticsResponse>();
  const [dataChart, setDataChart] = useState<DataChart[]>();
  const [dataBarChart, setDataBarChart] = useState<BarDatum[]>();
  const [barChartKeys, setBarChartKeys] = useState<string[]>();
  const [barChartLabels, setBarChartLabels] = useState<Map<string, string>>();
  const [chartType, setChartType] = useState<string>(CHART_OPTIONS[0].value);

  const toggleChartType = (type: string) => setChartType(type)


  useEffect(() => {
    setTheme(userActions.getUserTheme());
    assetActions().getGraphData(id, requestType, wasAssetHubRefreshedClicked).then((result) => {
      setGroupStatus(result)
    })
  }, [requestType, wasAssetHubRefreshLoading]);



  useEffect(() => {
    const dataChartAdapter = (): DataChart[] => {
      const data = [] as DataChart[];
      if (groupStatus != null && groupStatus?.values?.length > 0) {
        groupStatus.values.forEach((group) => {
          data.push({
            id: group.key ?? group.label,
            value: group.value.toString(),
            customLabel: group.label,
          });
        });
      }
      return data as DataChart[];
    }
    const dataBarChartAdapter = (): BarDatum[] => {
      const data = [] as BarDatum[];
      const obj = {} as BarDatum;
      if (groupStatus != null && groupStatus?.values?.length > 0) {
        groupStatus.values.forEach((group) => {
          obj[group.key ?? group.label] = group.value?.toString();
        });
        data.push(obj);

      }
      return data as BarDatum[];
    }
    const barChartKeysAdapter = (): string[] => {
      const data = [] as string[];
      if (groupStatus != null && groupStatus?.values?.length > 0) {
        groupStatus.values.forEach((group) => {
          data.push((group.key ?? group.label)?.toString());
        });
      }
      return data as string[];
    }
    const barChartLabelsAdapter = (): Map<string, string> => {
      const data = new Map<string, string>();
      if (groupStatus != null && groupStatus?.values?.length > 0) {
        groupStatus.values.forEach((group) => {
          const lkey = group.key ?? group.label
          data.set(`${lkey}`, `${group.label}`);
        });
      }
      return data as Map<string, string>;
    }
    setDataChart(dataChartAdapter);
    setDataBarChart(dataBarChartAdapter);
    setBarChartKeys(barChartKeysAdapter);
    setBarChartLabels(barChartLabelsAdapter);
  }, [groupStatus])

    return (
      <>
        <div className='xa-chart_asset'>
        {groupStatus?.values == null ? <EmptyRequestDisplay /> : ''}
          {chartType === CHART_OPTIONS[0].value && (
            <ResponsivePie
              valueFormat=" >-,~f"
                // @ts-ignore
              tooltip={function (e) { return (<span style={{ padding: 12, color: e.datum.color, background: '#222222' }}>{e.datum.data.customLabel}: {e.datum.formattedValue}</span>) }}
              key={`nivo-pie-${theme}`}
                // @ts-ignore
              data={dataChart ?? []}
              margin={{ top: 40, right: 165, bottom: 80, left: 80 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{
                from: 'color',
                modifiers: [
                  [
                    'darker',
                    0.2
                  ]
                ]
              }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor={'var(--xa-text)'}
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: 'color' }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: 'color',
                modifiers: [
                  [
                    'darker',
                    100
                  ]
                ]
              }}
              defs={[
                {
                  id: 'lines',
                  type: 'patternLines',
                  background: 'inherit',
                  color: 'var(--xa-text)',
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10
                }
              ]}
              legends={[
                {
                  anchor: 'right',
                  direction: 'column',
                  justify: false,
                  translateX: 105,
                  // translateY: 56,

                  itemsSpacing: 5,
                  itemWidth: 80,
                  itemHeight: 18,
                  itemTextColor: 'var(--xa-text)',
                  itemDirection: 'left-to-right',
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: 'circle',
                }
              ]}
              theme={{
                tooltip: {
                  container: {
                    background: theme === 'dark' ? 'var(--xa-black)' : 'var(--xa-white)',
                  },
                },
              }}
            />
          )}
          {chartType === CHART_OPTIONS[1].value && (
            <ResponsiveBar
              valueFormat=" >-,~f"
              tooltip={function (e) { return (<span style={{ padding: 12, color: e.color, background: '#222222' }}>{barChartLabels?.get(`${e.id}`)}: {e.formattedValue}</span>) }}
              key={`nivo-bar-${theme}`}
              groupMode="grouped"
              indexBy={(data) => {
                for (const item of Object.keys(data)) {
                  const barChart = barChartLabels?.get(item);
                  if (barChart !== undefined && barChart !== null) {
                    return item;
                  }
                }
                return Object.keys(data)?.[0] as string;
              }}
              keys={barChartKeys}
              data={dataBarChart ?? []}
              margin={{ top: 40, right: 165, bottom: 80, left: 80 }}
              borderWidth={1}
              borderColor={{
                from: 'color',
                modifiers: [
                  [
                    'darker',
                    0.2
                  ]
                ]
              }}
              // labelTextColor={{

              // }}
              defs={[
                {
                  id: 'lines',
                  type: 'patternLines',
                  background: 'inherit',
                  color: 'var(--xa-text)',
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10
                }
              ]}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: 'right',
                  direction: 'column',
                  justify: false,
                  translateX: 105,
                  // translateY: 56,
                  itemsSpacing: 5,
                  itemWidth: 80,
                  itemHeight: 18,
                  itemTextColor: 'var(--xa-text)',
                  itemDirection: 'left-to-right',
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: 'circle',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemBackground: theme === 'dark' ? 'rgba(var(--xa-white-rgb), 0.2)' : 'rgba(var(--xa-black-rgb), 0.03)',
                        itemOpacity: 1
                      }
                    }
                  ]
                }
              ]}
              theme={{
                textColor: theme === 'dark' ? 'var(--xa-white)' : 'var(--xa-black)',
                tooltip: {
                  container: {
                    background: theme === 'dark' ? 'var(--xa-black)' : 'var(--xa-white)',
                  },
                },
              }}
            />
          )}
        </div>
        <div className="xa-chart_asset-footer">
          <ToggleSwitchWithText onChange={toggleChartType} options={CHART_OPTIONS} selectedValue={chartType}  name="change_chart" />
        </div>
      </>
    )
}

export default ChartHub;
