import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { UserInfo, administrationToolsActions } from 'actions/administration-tools';

import { useDispatch, useSelector } from 'react-redux';
import { ThemeState } from 'state/themeReducer';
import { clearUsers, updateUserFromRegisteredToNew } from 'state/administrationToolsReducer';
import { reactColumnDefs } from './columnDefs';
import {MdMoreHoriz } from "react-icons/md";
import { RenderGridOptions, restoreGridState, saveGridState } from './utils';
import CommentsLoader from './CommentsLoader';


type RegisteredUsersProps = {};


const RegisteredUsers: React.FC<RegisteredUsersProps> = () => {
  const theme = useSelector((state: { theme: ThemeState }) => state.theme.theme)
  const newUserToRegistered = useSelector((state: any) => state.administrationTools.user)
  const gridRef = useRef<AgGridReact>(null);
  const [modalIsOpen,setModalIsOpen] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<UserInfo | null>(null)
  const [rowData, setRowData] = useState<UserInfo[]>([]);
  const dispatch = useDispatch()

  const [columnDefs, _] = useState([...reactColumnDefs,{
    headerName: 'ACTIONS',
    field: 'actions',
    filter: false,
    sortable: false,
    minWidth: 96,
    maxWidth: 96,
    width: 96,
    cellRenderer: (data: any) => {
      return <div className='xa-grid-actions-cell'>
        <RenderRowOptions data={data} />
      </div>
    },
  }]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: true,
    flex: 1,
    minWidth: 100,
    resizable: true
  }), []);

  const makeUserAdmin = async (tenant: string, id: number) => {
    try {
      const newUserData = await administrationToolsActions().makeUserAdmin({tenant, userId: id});
      setRowData(prevRowData => {
        const userIndex = prevRowData.findIndex(user => user.id === id);
        if (userIndex !== -1) {
          const updatedRowData = [...prevRowData];
          updatedRowData[userIndex] = newUserData;
          return updatedRowData;
        }
  
        return prevRowData;
      });
    } catch (error) {
      console.error("Error making user admin:", error);
    }
  }

  const toggleActivateUser = async (tenant: string, id: number) => {
    try {
      const newUserData = await administrationToolsActions().toggleActivateUser({tenant, userId: id});
      dispatch(updateUserFromRegisteredToNew(newUserData))
      setRowData(prevRowData => {
        const userIndex = prevRowData.findIndex(user => user.id === id);
        if (userIndex !== -1) {
          const updatedRowData = [...prevRowData];
          updatedRowData[userIndex] = newUserData;
          return updatedRowData;
        }
  
        return prevRowData;
      });
    } catch (error) {
      console.error("Error while activating user:", error);
    }
  }

  const commentUser = async (tenant: string, id: number, comment: string) => {
    try {
      const newUserData = await administrationToolsActions().commentUser({tenant, userId: id, comment});
      dispatch(updateUserFromRegisteredToNew(newUserData))
      setRowData(prevRowData => {
        const userIndex = prevRowData.findIndex(user => user.id === id);
        if (userIndex !== -1) {
          const updatedRowData = [...prevRowData];
          updatedRowData[userIndex] = newUserData;
          return updatedRowData;
        }
  
        return prevRowData;
      });
    } catch (error) {
      console.error("Error while leave comment:", error);
    }
  }

  const deleteUserComments = async (tenant: string, id: number) => {
    try {
      const newUserData = await administrationToolsActions().deleteUserComments({tenant, userId: id});
      dispatch(updateUserFromRegisteredToNew(newUserData))
      setCurrentUser(newUserData)
      setRowData(prevRowData => {
        const userIndex = prevRowData.findIndex(user => user.id === id);
        if (userIndex !== -1) {
          const updatedRowData = [...prevRowData];
          updatedRowData[userIndex] = newUserData;
          return updatedRowData;
        }
  
        return prevRowData;
      });
      
    } catch (error) {
      console.error("Error while deleting user's comment:", error);
    }
  }

  const onGridReady = useCallback((params: any) => {
    params.api.showLoadingOverlay();
    administrationToolsActions().getUsersInformation().then((response: UserInfo[]) => {
      setRowData(response);
      params.api.hideOverlay();
    })
    restoreGridState(params.api, params.columnApi);
  }, []);

  const emptyGrid = '<div class="xa-grid-empty"><div class="image"></div><div class="text">No rows found!</div></div>';

  useEffect(() => {
    if(newUserToRegistered){
      setRowData(prevRowData => {
        const userIndex = prevRowData.findIndex(user => user.id === newUserToRegistered.id);
  
        if (userIndex !== -1) {
          const updatedRowData = [...prevRowData];
          updatedRowData[userIndex] = newUserToRegistered;
  
          return updatedRowData;
        }
  
        return prevRowData;
      });
      dispatch(clearUsers())
    }
  },[newUserToRegistered])

  const onBtnExport = useCallback(() => {
    if(!gridRef.current) return;
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const RenderRowOptions = ({data}: any) => {
    const [selectedKeys, setSelectedKeys] = useState([]);

    const onSelect = ({ key }: any) => {
      if(key == '1'){
        makeUserAdmin(data.data.tenant,data.data.id)
      }
      if(key == '2'){
        toggleActivateUser(data.data.tenant, data.data.id)
      }
      if(key == '3'){
        setModalIsOpen(true)
        setCurrentUser(data?.data)
      }
      setSelectedKeys([]);
    }

    const menu = (
      <Menu onSelect={onSelect} selectedKeys={selectedKeys}>
         <MenuItem key={1}>{data?.data?.admin ? 'Remove': 'Make'} admin</MenuItem>
         <MenuItem key={2}>{data?.data?.deactivated ? 'Activate' : 'Deactivate'}</MenuItem>
         <MenuItem key={3}>View comments</MenuItem>
      </Menu>
    );
  
    return (
      <Dropdown
        trigger={['click']}
        overlay={menu}
        animation="slide-up"
        placement="bottomRight"
      >
        <div className='xa-grid-more-btn'>
          <MdMoreHoriz />
        </div>
      </Dropdown>
    )
  }

  const saveGrid = () => {
    if (gridRef.current) {
      saveGridState(gridRef.current.api, gridRef.current.columnApi);
    }
  }

  return (
    <div className='xa-admin-column registered-users'>
      <div className='xa-section-title'>Registered Users</div>
      <div className={`xa-grid${theme === 'dark' ? ' ag-theme-alpine-dark' : ' ag-theme-alpine'}`}>
        <div className='xa-grid-btns'>
          <RenderGridOptions onBtnExport={onBtnExport} />
        </div>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={false}
          suppressRowClickSelection={true}
          pagination={true}
          onGridReady={onGridReady}
          paginationPageSize={20}
          overlayNoRowsTemplate={emptyGrid}
          onColumnMoved={() => saveGrid()}
          onColumnResized={() => saveGrid()}
          onFilterChanged={() => saveGrid()}
          onSortChanged={() => saveGrid()}
        />
         <CommentsLoader isOpen={modalIsOpen} setIsOpen={setModalIsOpen} currentUser={currentUser} commentUser={commentUser} deleteUserComments={deleteUserComments}  />
      </div>
    </div>
  );
}

export default RegisteredUsers;
