import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { vestResolver } from '@hookform/resolvers/vest';
import { create, test, enforce } from 'vest';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Link } from "react-router-dom";
import { useNotification } from 'rc-notification';
import Button from 'components/Button';
import { FormValues } from 'models/Login';
import { useUserActions } from 'actions/user';
import 'rc-notification/assets/index.css';
import './style.scss';

const validationSuite = create((data = {}) => {
  test('username', 'Username is required', () => {
    enforce(data.username).isNotEmpty();
    enforce(data.username).isString();
  });

  test('password', 'Password is required', () => {
    enforce(data.password).isNotEmpty();
  });
});

function Login() {
  const userActions = useUserActions();
  const [genericErrors, setGenericErrors] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [tenants, setTenants] = useState<Array<string>>(new Array<string>());
  const [selectedTenant, setSelectedTenant] = useState<string | null>(null);
  const location = useLocation();
  const [notice, contextHolder] = useNotification({ closable: true });

  useEffect(() => {
    if (location.state == 'fromRegister') {
      notice.open({
        content: "Account registered succesfully",
      });
    }
  }, [location]);

  useEffect(() => {
    const onGetTenants = async () => {
      const response = await userActions.getTenants();
      if (response.length > 0) {
        setSelectedTenant(response[0]);
      }
      setTenants(response);
    }
    onGetTenants();
  }, []);

  const onChangeTenant = (ten: string) => {
    setSelectedTenant(ten);
  };

  const { register, handleSubmit, formState: { errors } } = useForm<FormValues>({
    resolver: vestResolver(validationSuite),
  });

  const toggleShowPassword = () => setShowPassword(prev => !prev);

  const onLogin = async (data: FormValues) => {
    try {
      await userActions.login(data);
      setGenericErrors(null);
    } catch (err: any) {
      setGenericErrors(err.response.data);
    }
  }

  const onSubmit = handleSubmit((data) => onLogin({ ...data, tenantId: selectedTenant }), (error) => console.log('error', error));

  return (
    <>
      {contextHolder}
      <div className="xa-auth-page-form-wrapper">
        <form>
          <div className="xa-form-field">
            <label htmlFor="groups" className="required">Tenants</label>
            <select name="groups" id="groups" onChange={(e) => onChangeTenant(e.target.value)}>
              {tenants?.map((value) => (
                <option value={value} key={value} selected={selectedTenant === value}>
                  {value}
                </option>))}
            </select>
          </div>
          <div className="xa-form-field">
            <label htmlFor="username" className="required">Username</label>
            <input type="text" id="username" autoComplete="username" required {...register("username")} className={errors?.username?.message && 'invalid'} />
            <div className="xa-auth-page-form-error">
              {errors?.username?.message && (errors.username.message)}
            </div>
          </div>
          <div className="xa-form-field">
            <label htmlFor="password" className="required">Password</label>
            <div className="relative">
              <input type={showPassword ? "text" : "password"} id="password" autoComplete="password" required {...register("password")} className={errors?.password?.message && 'invalid'} />
              <div className="xa-auth-page-form-password-view" onClick={toggleShowPassword}>
                {showPassword ? (<FiEye />) : (<FiEyeOff />)}
              </div>
            </div>
            <div className="xa-auth-page-form-error">
              {errors?.password?.message && (errors.password.message)}
            </div>
          </div>
          <div className="xa-auth-page-form-link">
            <Link to="/register">Register?</Link>
          </div>
          {genericErrors && (
            <div className="xa-auth-page-form-errors">
              {genericErrors}
            </div>
          )}
          <Button onClick={onSubmit} color="primary" full isButton>
            Sign in
          </Button>
        </form>
      </div>
    </>
  );
}

export default Login;
