import dayjs from 'dayjs';
import { get } from 'utils/request';
import { getRefreshDataHeader } from "../utils/getRefreshDataHeader";

export interface SurveyInfo {
  id: number,
  name: string,
  surveyTypeCode: string,
  surveyDate: string,
  year: number,
}

const surveyActions = () => {

  const getSurveyDetails = async (surveyId: string, refreshData: boolean = false): Promise<SurveyInfo> => {
    const response = await get<SurveyInfo>(`surveys/${surveyId}`, getRefreshDataHeader(refreshData));
    const responseData = response.data;
    if (responseData.surveyDate) responseData.surveyDate = dayjs(responseData?.surveyDate).format('YYYY-MM-DD');
    return responseData;
  }

  const getSurveyInspectionDetails = async (surveyId: string, refreshData: boolean = false): Promise<SurveyInfo> => {
    const response = await get<SurveyInfo>(`surveys/inspections/${surveyId}`, getRefreshDataHeader(refreshData));
    const responseData = response.data;
    if (responseData.surveyDate) responseData.surveyDate = dayjs(responseData?.surveyDate).format('YYYY-MM-DD');
    return response.data;
  }

  return {
    getSurveyDetails,
    getSurveyInspectionDetails
  };
};

export { surveyActions };
