import Modal from 'react-modal';
import { useEffect, useState } from 'react';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { assetActions, AssetTypeAttributesInfo } from 'actions/asset';
import renderType from './lineTypesRenderer';
import renderMarkerType from './makerTypesRenderer';
import { MdClose, MdEdit } from 'react-icons/md';
import { TbArrowsMinimize, TbArrowsMaximize } from 'react-icons/tb';
import { CgArrowLongRight } from 'react-icons/cg';
import Draggable from 'react-draggable';
import Select, { SingleValue } from 'react-select';
import { SymbologyModel } from 'models/SymbologyModel';
import { RgbaColor, RgbaColorPicker } from "react-colorful";
import { LayerRuleStypeElement } from 'models/LayerRuleStyle';
import chroma from 'chroma-js';
import { red } from 'utils/colors';

interface AutoCategoriseRulesProps {
  isOpen: boolean,
  closeModal: () => void,
  itemKey: string,
  category: string,
  layerType?: string,
  defaultStyle: SymbologyModel,
  layerRules: LayerRuleStypeElement[],
  setLayerRules: (layerRuleStypeElements: LayerRuleStypeElement[]) => void,
  parameterId: number
};

interface WarningModalProps {
  isOpen: boolean,
  closeModal: () => void,
  onAccept: () => void,
  title: string | undefined,
};

interface ColorPickerModalProps {
  isOpen: boolean,
  closeModal: () => void,
  color: RgbaColor,
  setColor: (newColor: RgbaColor) => void,
}

const AutoCategoriseRules: React.FC<AutoCategoriseRulesProps> = ({
  isOpen = false,
  closeModal = () => { },
  itemKey,
  layerType = 'both',
  category,
  defaultStyle,
  layerRules,
  setLayerRules,
  parameterId
}) => {
  const [isModalMinimized, setModalMinimized] = useState<boolean>(false);
  const [warningModalOpened, setWarningModalOpened] = useState<boolean>(false);
  const [colorPickerModalOpened, setColorPickerModalOpened] = useState<boolean>(false);
  const [assetTypeAttributesInfo, setAssetTypeAttributesInfo] = useState<AssetTypeAttributesInfo[]>([]);
  const [color, setColor] = useState<RgbaColor>(defaultStyle?.currentColor || red);
  const [attributes, setAttributes] = useState<{
    label: string,
    value: string,
  }[]>([]);
  const [attribute, setAttribute] = useState<SingleValue<{
    label: string,
    value: string,
  }> | null>();
  const [lookupValues, setLookupValues] = useState<{
    label: string,
    value: string,
  }[]>();

  const toggleModalState = () => setModalMinimized(prevState => !prevState);

  useEffect(() => {
    assetActions().getAssetTypeAttributes(itemKey.split('+')[0], itemKey.split('+')[1], itemKey, parameterId).then((result) => {
      let objResult = [];
      if(!Array.isArray(result)) {
        objResult = [result];
      } else {
        objResult = result;
      }
      const res = objResult.filter(x => (x.lookupValues || []).length > 0 && x.hasLookups);
      if(res.length > 0) {
        setAttributes(res.map(x => ({ value: x.name, label: x.name })));
      }
      setAssetTypeAttributesInfo(res);
    });
  }, [itemKey]);

  const closeWarningModal = () => {
    setWarningModalOpened(false);
  }

  const getLayerStyle = (color: RgbaColor) => {
    return({
      ...defaultStyle,
      currentColor: color,
    });
  };

  const generateRules = () => {
    closeWarningModal();
    const rules: LayerRuleStypeElement[] = layerRules.filter(r => r.name !== attribute?.value);
    if(lookupValues === undefined){
      return;
    }
    var startColor = chroma(Object.values(defaultStyle?.currentColor || { r: 48, g: 54, b: 150, a: 1 })).hex();
    var endColor = chroma(Object.values(color)).hex();
    const palette = chroma.scale([startColor, endColor]).mode('lch').colors(lookupValues.length);



    for(let i = 0; i < (lookupValues|| []).length; i++) {
      const currentValue = lookupValues[i];
      const currentColor = chroma(palette[i]).rgba();
      const rgbaColor: RgbaColor = {r: currentColor[0], g: currentColor[1], b: currentColor[2], a: currentColor[3]};
      rules.push({name: attribute?.value|| '', value: currentValue.value, style: getLayerStyle(rgbaColor),
      dataType: "String",
      firstComparison: null,
      secondComparison: null,
      ruleChaining: null,
      firstValue: null,
      secondValue: null});
    }
    setLayerRules(rules);
    closeModal();
  }

  const onSubmit = () => {

    const existingRules = layerRules.filter(r => r.name === attribute?.value);

    if((existingRules || []).length > 0){
      setWarningModalOpened(true);
    } else {
      generateRules();
    }
  }

  const onAttributeChange = (newVal: SingleValue<{
    label: string,
    value: string,
  }>) => {
    setAttribute(newVal);
    const atai = assetTypeAttributesInfo.filter(x => x.name === newVal?.label);
    if(atai.length > 0) {
      setLookupValues(atai[0].lookupValues.map(x => ({ value: x, label: x })));
    }
  }

  const [windowSize, setWindowSize] = useState({
    innerWidth: 0,
    innerHeight: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const customStyles = {
    content: {
      left: `${(windowSize.innerWidth - 378 - 203) / 2}px`,
      top: `${(windowSize.innerHeight - 425) / 2}px`,
    }
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      bodyOpenClassName={null}
      portalClassName="react-modal-container"
      overlayClassName="react-modal-overlay"
      className="react-modal-wrapper"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      style={customStyles}
    >
      <Draggable
        enableUserSelectHack={false}
        handle=".modal-title"
        bounds="body"
      >
        <div className={`react-modal symbology-modal ${isModalMinimized ? ' minimized' : ''}`}>
          <div className="modal-header">
            <div className="modal-title">Auto Categorise rules: {category}</div>
            <div className="modal-header-buttons">
              <div className="modal-control" onClick={toggleModalState}>
                {isModalMinimized ? <TbArrowsMaximize /> : <TbArrowsMinimize />}
              </div>
              <div className="modal-close" onClick={() => closeModal()}>
                <MdClose />
              </div>
            </div>
          </div>
          <div className="modal-content">
            {(warningModalOpened || colorPickerModalOpened) && <Loader />}
            <div className="xa-form-field">
              <strong>Attribute</strong>
              <Select
                options={attributes}
                onChange={onAttributeChange}
                value={attribute}
                className={'custom_select'}
                menuPlacement="bottom"
                classNamePrefix="custom_select"
              />
            </div>
            {(layerType === 'simple-marker' || layerType === 'both') &&
            (
              <fieldset className="xa-visualisation-rules-symbology">
                <legend>Symbology</legend>
                <div></div>
                <div>
                  {renderMarkerType(defaultStyle?.currentStyle || '', defaultStyle?.currentColor || { r: 48, g: 54, b: 150, a: 1 })}
                </div>
                <CgArrowLongRight />
                <div>
                  {renderMarkerType(defaultStyle?.currentStyle || '', color || { r: 48, g: 54, b: 150, a: 1 })}
                </div>
                <button className="xa-symbology-edit" onClick={() => setColorPickerModalOpened(true)}>
                  <MdEdit />
                </button>
              </fieldset>
            )}
            {(layerType === 'simple-line' || layerType === 'both') &&
            (
              <fieldset className="xa-visualisation-rules-symbology">
                <legend>Symbology</legend>
                <div></div>
                <div>
                  {renderType(defaultStyle?.currentStyle || '', defaultStyle?.currentColor || { r: 48, g: 54, b: 150, a: 1 })}
                </div>
                <CgArrowLongRight />
                <div>
                  {renderType(defaultStyle?.currentStyle || '', color || { r: 48, g: 54, b: 150, a: 1 })}
                </div>
                <button className="xa-symbology-edit" onClick={() => setColorPickerModalOpened(true)}>
                  <MdEdit />
                </button>
              </fieldset>
            )}
          </div>

          {warningModalOpened && (
            <WarningModal
            isOpen={warningModalOpened}
            closeModal={closeWarningModal}
            onAccept={generateRules}
            title={attribute?.value}
            />
          )}

          {colorPickerModalOpened && (
            <ColorPickerModal
              isOpen={colorPickerModalOpened}
              closeModal={() => setColorPickerModalOpened(false)}
              color={color}
              setColor={setColor}
            />
          )}

          <div className="modal-footer">
            <Button onClick={onSubmit} color="primary" full isButton disabled={!attribute}>
              Apply
            </Button>
          </div>
        </div>
      </Draggable>
    </Modal >
  );
}

const WarningModal: React.FC<WarningModalProps> = ({
  isOpen = false,
  closeModal = () => { },
  onAccept = () => {},
  title,
}) => {

  const [windowSize, setWindowSize] = useState({
    innerWidth: 0,
    innerHeight: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const customStyles = {
    content: {
      left: `${(windowSize.innerWidth - 378 - 203) / 2}px`,
      top: `${(windowSize.innerHeight - 425) / 2}px`,
    }
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      bodyOpenClassName={null}
      portalClassName="react-modal-container"
      overlayClassName="react-modal-overlay"
      className="react-modal-wrapper"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      style={customStyles}
    >
      <Draggable
        enableUserSelectHack={false}
        handle=".modal-title"
        bounds="body"
      >
        <div className={`react-modal warning-modal`}>
          <div className="modal-header">
            <div className="modal-title">Warning!</div>
            <div className="modal-header-buttons">
              <div className="modal-close" onClick={() => closeModal()}>
                <MdClose />
              </div>
            </div>
          </div>
          <div className="modal-content">
            <div className="mt-12">
              <strong>There is already a rule for {title}</strong>
            </div>
          </div>

          <div className="modal-footer">
            <Button onClick={onAccept} color="primary" full isButton>
              Accept
            </Button>
            <Button onClick={closeModal} color="secondary" full isButton>
              Cancel
            </Button>
          </div>
        </div>
      </Draggable>
    </Modal >
  );
};

const ColorPickerModal: React.FC<ColorPickerModalProps> = ({
  isOpen = false,
  closeModal = () => { },
  color,
  setColor = () => {},
}) => {
  const [localColor, setLocalColor] = useState<RgbaColor>(color);


  const onAccept = () => {
    setColor(localColor);
    closeModal();
  }

  const [windowSize, setWindowSize] = useState({
    innerWidth: 0,
    innerHeight: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const customStyles = {
    content: {
      left: `${(windowSize.innerWidth - 378 - 203) / 2}px`,
      top: `${(windowSize.innerHeight - 425) / 2}px`,
    }
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      bodyOpenClassName={null}
      portalClassName="react-modal-container"
      overlayClassName="react-modal-overlay"
      className="react-modal-wrapper"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      style={customStyles}
    >
      <Draggable
        enableUserSelectHack={false}
        handle=".modal-title"
        bounds="body"
      >
        <div className={`react-modal symbology-modal`}>
          <div className="modal-header">
            <div className="modal-title">Warning!</div>
            <div className="modal-header-buttons">
              <div className="modal-close" onClick={() => closeModal()}>
                <MdClose />
              </div>
            </div>
          </div>
          <div className="modal-content">
            <div className="mt-12">
              <RgbaColorPicker color={color} onChange={setLocalColor} />
            </div>
          </div>

          <div className="modal-footer">
            <Button onClick={onAccept} color="primary" full isButton>
              Apply
            </Button>
            <Button onClick={closeModal} color="secondary" full isButton>
              Cancel
            </Button>
          </div>
        </div>
      </Draggable>
    </Modal >
  );
};

export default AutoCategoriseRules;
