import React, { useEffect, useState } from 'react';
import { Outlet } from "react-router-dom";
import { useUserActions } from 'actions/user';
import XALogoLight from 'assets/images/xa-logo-b.webp';
import XALogoDark from 'assets/images/xa-logo-w.webp';
import './style.scss';

function AuthLayout() {
  const [theme, setTheme] = useState('');
  const userActions = useUserActions();
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  useEffect(() => {
    setTheme(userActions.getUserTheme());
  }, []);

  const changeTheme = () => {
    userActions.toggleUserTheme();
    setTheme(userActions.getUserTheme());
  };

  return (
    <div className="main-content">
      <section className="xa-auth-page">
        <div className="xa-auth-page-content">
          <div className="xa-auth-page-theme-toggle" onClick={changeTheme} />
          <div className="xa-auth-page-content-wrapper">
            <div className="xa-auth-page-login-box">
              <div className="xa-auth-page-title">
                <h1>
                  XA<sup>&copy;</sup> Explorer
                </h1>
              </div>
              <Outlet />
              <div className="xa-auth-page-logo">
                <img src={theme === 'dark' ? XALogoDark : XALogoLight} alt="XA Logo" />
              </div>
            </div>
          </div>
          <div className="xa-auth-page-copyright">
            &copy;
            {' '}
            {getCurrentYear()}
            {' XAIS Asset Management Ltd. All Rights Reserved.'}
          </div>
          <div className="xa-auth-page-separator">
            <div className="xa-auth-page-polygon"></div>
          </div>
        </div>
        <div className="xa-auth-page-image"></div>
      </section>
    </div>
  );
}

export default AuthLayout;
