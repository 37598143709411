import { get } from 'utils/request';
import { AssetInfo } from "@actions/asset";
import { getRefreshDataHeader } from "../utils/getRefreshDataHeader";

export interface Layers {
  name: string,
  year: number,
  key: number,
  subCategories: [],
  enabled: boolean,
  endPoint: string,
}

export interface Categories {
  name: string,
  layers: Layers[]
}

export interface LegendResponse {
  externalSources: [],
  layerCategories: Categories[]
}

const useLegendActions = () => {

  const getLegend = async (refreshLegendToolbar: boolean = false): Promise<LegendResponse> => {
    const response = await get<LegendResponse>('visualiser/legend', getRefreshDataHeader(refreshLegendToolbar));
    return response.data;
  }

  return {
    getLegend
  };

};

export { useLegendActions };
