import dayjs from 'dayjs';
import { post } from 'utils/request';
import { getRefreshDataHeader } from "../utils/getRefreshDataHeader";

export interface SectionInfo {
  sectionLabel: string,
  description: string,
  length: number,
  startNode: string,
  endNode: string,
  roadName: string,
  roadNumber: string,
  roadHierarchy: string,
  roadClass: string,
  roadType: string,
  lanes: number,
  width: number,
  speedLimit: string,
  environment: string,
  district: string,
  region: string,
  ward: string,
  startDate: string,
  dotCode: string,
  adopted: boolean,
  cogpHierarchy: string,
  usrn: string,
  geometry: string,
}

const networkActions = () => {

  const getSectionDetails = async (label: string, refreshData: boolean = false): Promise<SectionInfo> => {
    const response = await post<any, SectionInfo>('network/sections', { 'SectionLabel': label }, getRefreshDataHeader(refreshData));
    const responseData = response.data;
    if (responseData.startDate) responseData.startDate = dayjs(responseData?.startDate).format('YYYY-MM-DD');
    return responseData;
  }

  return {
    getSectionDetails,
  };
};

export { networkActions };
