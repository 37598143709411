import { get } from 'utils/request';


export interface PaginationData {
  currentPage: number,
  totalPages: number,
  totalItems: number,
  itemsPerPage: number,
}
export interface SearchResultsData {
  results: string[],
  pagination: PaginationData,
}

export const defaultPaginationData : PaginationData = {
  currentPage: 0,
  totalItems: 0,
  itemsPerPage: 0,
  totalPages: 0,
}

const searchActions = () => {

  const searchNetwork = async (keyword: string, pageNumber: number, pageSize: number): Promise<SearchResultsData> => {

    const response = await get<string[]>('search/network', { params: { keyword, pageNumber, pageSize } });

    if(response.status === 200){
      const result: SearchResultsData = {
        results: response.data
        , pagination: JSON.parse(response.headers.pagination || '')
      }
      return result;
    } else {
      const result: SearchResultsData  = {results: [], pagination: defaultPaginationData};
      return result;
    }
  }

  const searchAssets = async (keyword: string, assetTypeCode: string, pageNumber: number, pageSize: number): Promise<SearchResultsData> => {
    const response = await get<string[]>('search/assets', { params: { keyword, assetTypeCode, pageNumber, pageSize } });

    if(response.status === 200){
      const result: SearchResultsData = {
        results: response.data
        , pagination: JSON.parse(response.headers.pagination || '')
      }
      return result;
    } else {
      const result: SearchResultsData  = {results: [], pagination: defaultPaginationData};
      return result;
    }
  }

  const searchAccidents = async (keyword: string, year: string, pageNumber: number, pageSize: number): Promise<SearchResultsData> => {
    const response = await get<string[]>('search/accidents', { params: { keyword, year, pageNumber, pageSize } });

    if(response.status === 200){
      const result: SearchResultsData = {
        results: response.data
        , pagination: JSON.parse(response.headers.pagination || '')
      }
      return result;
    } else {
      const result: SearchResultsData  = {results: [], pagination: defaultPaginationData};
      return result;
    }
  }

  return {
    searchNetwork,
    searchAssets,
    searchAccidents
  };
};

export { searchActions };
