import { get } from 'utils/request';
import { getRefreshDataHeader } from "../utils/getRefreshDataHeader";

export interface StatisticsValue {
  label: string,
  key: string,
  value: number
}

export interface StatisticsResponse {
  name: string,
  grouping: string,
  values: StatisticsValue[]
}

export interface GroupsResponse {
  id: number,
  name: string,
}

export interface DisplayCodesResponse {
  code: string,
  name: string,
  tied: boolean
}

const statisticsActions = () => {

  const getGroups = async (): Promise<GroupsResponse[]> => {
    const response = await get<GroupsResponse[]>('assets/groups');

    return response.data;
  }

  const getDisplayCodes = async (): Promise<DisplayCodesResponse[]> => {
    const response = await get<DisplayCodesResponse[]>('config/displaycodes');

    return response.data;
  }

  const getGroupStatus = async (groupId: number, refreshData: boolean = false): Promise<StatisticsResponse> => {
    const response = await get<StatisticsResponse>(`stats/groups/${groupId}`, getRefreshDataHeader(refreshData));

    return response.data;
  }

  const getDefaultTypeStats = async (assetTypeCode: string): Promise<StatisticsResponse> => {
    const response = await get<StatisticsResponse>(`stats/types/${assetTypeCode}`);

    return response.data;
  }

  const getTypeStats = async (assetTypeCode: string, displayCode: string, refreshData: boolean = false): Promise<StatisticsResponse> => {
    const response = await get<StatisticsResponse>(`stats/types/${assetTypeCode}/${displayCode}`, getRefreshDataHeader(refreshData));

    return response.data;
  }

  return {
    getGroups,
    getDisplayCodes,
    getGroupStatus,
    getDefaultTypeStats,
    getTypeStats
  };
};

export { statisticsActions };
